import React, { Fragment, useEffect } from 'react';

const Proximamente = (props) => {

    const { title, menuCode, setMenuActive } = props;

    useEffect(() => {
        setMenuActive(menuCode);
    }, [setMenuActive, menuCode]);

    return (
        <Fragment>
            <div className="panel-separator"></div>
            <div className="panel-pane pane-texto">
                <div className="pane-content">
                    <h2 className="pane-title">
                        {title}
                    </h2>
                    <p>Próximamente.</p>
                </div>
            </div>
            <div className="panel-separator"></div>
        </Fragment>
    )
}
export default Proximamente;