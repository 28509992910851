import React, { Fragment, useState } from 'react';
import { useForm } from "react-hook-form";
import { buildUrl } from '../../api/utils'

const Account = (props) => {

    const { setAccountState, convocatoria, setToken} = props;

    const { register, errors, handleSubmit } = useForm();
    const [errores, setErrores] = useState([]);

    const btnLikeA = {
        background: 'transparent',
        border: 'none',
        padding: '0!important',
        color: '#069',
        textDecoration: 'underline',
        cursor: 'pointer',
    }

    const onSubmit = async (data) => {
        let resStatus = 0;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'username': `CONV${convocatoria.id}_${data.username}`,
                'password': data.password
            }),
        };
        fetch(buildUrl(`/token-auth/`), requestOptions)
        .then(response => {
            resStatus = response.status
            return response.json()
        })
        .then(response => {
            switch (resStatus) {
                case 200:
                    setToken(response.token);
                    break;
                case 400:
                    setErrores(["DNI o Clave erróneos"])
                    break;
                default:
                    console.log(`Algo salio mal ingresando al sistema. Error: ${response}`);
            }
        })
        .catch(err => {
            console.log(`Algo salio mal ingresando al sistema. Error: ${err}`);
            return false;
        })
    }

    return (
        <Fragment>
            <div className="panel-pane pane-texto">
                <div className="pane-content">
                    <div className="row">
                        <div className="col-xs-12">
                            <h4 className="pane-title">1- Registrar nuevo Equipo</h4>
                            {
                                convocatoria.manual_inscripcion &&
                                <>
                                    <a
                                      href={convocatoria.manual_inscripcion}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Manual de Inscripciones"
                                      style={{float: 'left', fontSize: '22px', marginRight: '12px'}}>
                                        <i className="fa fa-question-circle-o"/>
                                    </a>
                                    <p>¿Cómo inscribo mi Equipo y obtengo mi clave?</p>
                                </>
                            }
                            <button type="button" onClick={() => setAccountState('register')} className="btn btn-primary">OBTENER CLAVE</button>
                        </div>
                    </div>
                    <h4 className="pane-title">
                        2- Completar Registro
                    </h4>
                    <div className="row">
                        <div className="col-md-6">
                            <div id="errores" aria-live="polite" className="alert alert-danger hidden">
                                <h5>Se han producido los siguientes errores:</h5>
                                <ol>
                                    <li>Ingresá tu usuario</li>
                                    <li>Ingresá tu clave</li>
                                </ol>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-md-10 item-form">
                                        <label >Ingresá DNI del Contacto Principal</label>
                                        <input 
                                            type="text" 
                                            name="username" 
                                            className="form-control"
                                            ref={register({ required: true })}
                                        />
                                        {errors.username && errors.username.type === "required" && 
                                        <p className="error text-danger">Ingresá tu dni</p>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-10 item-form">
                                        <label>Clave</label>
                                        <input 
                                            type="password" 
                                            className="form-control"
                                            name="password"
                                            ref={register({ required: true })}
                                        />
                                        {errors.password && errors.password.type === "required" && 
                                        <p className="error text-danger">Ingresá tu clave</p>}
                                    </div>
                                </div>
                                { errores.length > 0 && 
                                    errores.map(error => (
                                        <p key={error} className="error text-danger">{error}</p>
                                    ))
                                }
                                <div className="row">
                                    <div className="col-sm-12 m-t-2">
                                        <button type="submit" className="btn btn-primary">INGRESAR</button>
                                        <p><button onClick={() => setAccountState('recover')} style={btnLikeA}>Olvidé mi clave</button></p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="panel-separator"></div>
        </Fragment>
    )
}
export default Account;