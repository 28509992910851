import React, { Fragment, useEffect } from 'react';

const Home = (props) => {

    const { convocatoria, setMenuActive } = props;

    useEffect(() => {
        setMenuActive("title");
    }, [setMenuActive]);

    
    return (
        <Fragment>
            <div className="panel-pane pane-separador">
                <div className="pane-content">
                    <hr />
                </div>
            </div>
            <div className="panel-separator"></div>
            <div className="panel-pane pane-texto">
                <div className="pane-content" dangerouslySetInnerHTML={{__html: convocatoria.descripcionHtml}}>
                </div>
            </div>
            <div className="panel-separator"></div>
        </Fragment>
    )
}
export default Home;