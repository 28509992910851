import React, { Fragment, useEffect } from 'react';
import { REACT_APP_BACKEND_URL } from "../../api/utils";

const Notificaciones = (props) => {

    const { convocatoria, setMenuActive } = props;

    useEffect(() => {
        setMenuActive("NO");
    }, [setMenuActive]);

    return (
        <Fragment>
            <div className="panel-separator"></div>
            <div className="panel-pane pane-texto">
                <div className="pane-content">
                    <div className="listado-items">
                    {convocatoria.notificaciones.map(notificacion => (
                        <p key={notificacion.filename}>
                            <strong>{notificacion.fecha}  </strong>
                            <a 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                href={REACT_APP_BACKEND_URL+notificacion.link}
                            >
                                {notificacion.filename_text}
                            </a>
                        </p>
                    ))}
                    </div>
                </div>
            </div>
            <div className="panel-separator"></div>
        </Fragment>
    )
}
export default Notificaciones;
