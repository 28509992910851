import React, { Fragment, useState } from 'react';
import { useForm } from "react-hook-form";
import { buildUrl } from '../../api/utils'

const IngresarLlave = (props) => {

    const { setAccountState, convocatoria } = props;

    const { register, errors, handleSubmit } = useForm();
    const [errores, setErrores] = useState([]);
    const [llave, setLlave] = useState('')
    const [fetching, setFetching] = useState(false)

    const btnLikeA = {
        background: 'transparent',
        border: 'none',
        padding: '0!important',
        color: '#069',
        textDecoration: 'underline',
        cursor: 'pointer',
    }

    // const onSubmit = async (data) => {
    //     setLlave('llavesuperlocochona');
        // let resStatus = 0;
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({
        //         'username': `CONV${convocatoria.id}_${data.username}`,
        //         'password': data.password
        //     }),
        // };
        // fetch(buildUrl(`/token-auth/`), requestOptions)
        //     .then(response => {
        //         resStatus = response.status
        //         return response.json()
        //     })
        //     .then(response => {
        //         switch (resStatus) {
        //             case 200:
        //                 setToken(response.token);
        //                 break;
        //             case 400:
        //                 setErrores(["DNI o Clave erróneos"])
        //                 break;
        //             default:
        //                 console.log(`Algo salio mal ingresando al sistema. Error: ${response}`);
        //         }
        //     })
        //     .catch(err => {
        //         console.log(`Algo salio mal ingresando al sistema. Error: ${err}`);
        //         return false;
        //     })
    // }

    const onSubmit = () => {
        const endpoint = buildUrl('/llave/validar')
        setFetching(true)
        setErrores([])
        fetch(endpoint, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({ llave: llave })
        }).then(response => response.json())
          .then(dataLlave => {
            setFetching(false)
              if (dataLlave.valida ){
                  if (dataLlave.presento_propuesta){
                    setErrores(['Ya existe propuesta presentada con esta llave'])
                  } else {
                    props.setEquipo(dataLlave.equipo)
                    props.setLlave(llave)
                  }
              } else {
                  setErrores(['La llave ingresada no es válida'])
              }
          })
            .catch(err => {
                setFetching(false)
                setErrores(['La llave ingresada no es válida'])
            });

    }

    return (
        <Fragment>
            <div className="panel-pane pane-texto">
                <div className="pane-content">
                  {
                    convocatoria.manual_propuesta &&
                    <>
                      <a

                        href={convocatoria.manual_propuesta}
                        target="_blank"
                        rel="noopener noreferrer"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Manual de Propuestas"
                        style={
                          {float: 'left', fontSize: '22px', marginRight: '12px',
                            pointerEvents: convocatoria.link_manual_propuesta ? 'all' : 'none', cursor: convocatoria.link_manual_propuesta ? 'pointer': 'default'
                          }
                        }>
                        <i className="fa fa-question-circle-o"></i>
                      </a>
                      <p className="pane-title">¿Cómo presento mi propuesta?</p>
                    </>
                  }

                </div>
            </div>
            <div className="row">
                <div className="col-md-6">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="row">
                            <div className="col-md-10  item-form">
                                <i className="icono-arg-autentificacion" style={{fontSize: "20px !important", color: "#0072bb", float: "left", marginRight: "10px"}}></i>  
                                <label htmlFor="password">Llave</label>
                                <input id="password"
                                       onChange={e => setLlave(e.target.value)}
                                       type="password"
                                       className="form-control"
                                       name="password"
                                       style={{float: "left"}}
                                       disabled={fetching}
                                />
                                {errores.length > 0 && errores.map((e, i) =>
                                  <p className="error text-danger" key={`error-${i}`}>{e}</p>)
                                }
                            </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 m-t-2">
                                    <button type="submit"
                                            className="btn btn-primary"
                                            disabled={fetching}>
                                        INGRESAR
                                    </button>
                                </div>
                            </div>
                    </form>
                    <div className="row">
                        <div className="col-sm-12 m-t-2">
                            <p><button onClick={() => setAccountState('recuperar')} style={btnLikeA}>Olvidé mi llave</button></p>
                        </div>
                        <hr></hr>
                    </div>
                </div>


            </div>
            <div className="panel-separator"></div>
        </Fragment>
    )
}
export default IngresarLlave;