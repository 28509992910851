import React, { Fragment, useState, useEffect } from "react";
import FormularioIntegrante from "./FormularioIntegrante";
import {
  getIntegrante,
  editarIntegrante,
  crearIntegrante,
  getCategoriasData,
} from "../../api/convocatorias";

const CrearEditarIntegrante = (props) => {
  const {
    integranteID,
    convocatoria,
    categoria,
    categoriasData,
    setInscripcionState,
    presentado,
    token,
    equipo,
  } = props;

  const [estadoFormulario, setEstadoFormulario] = useState("completando");
  const [integrante, setIntegrante] = useState(null);
  const [categoriaData, setCategoriaData] = useState([]);
  const [integranteCondiciones, setIntegranteCondiciones] = useState([]);

  const [errores, setErrores] = useState([]);

  const onSubmit = async (data) => {
    setEstadoFormulario("posteando");
    if (integranteID === null) {
      const response = await crearIntegrante(data, token);
      if (response.error) {
        setErrores([response.error]);
      } else {
        setInscripcionState("integrante-creado");
      }
    } else {
      const response = await editarIntegrante(data, token);
      if (response.error) {
        setErrores([response.error]);
      } else {
        setInscripcionState("integrante-editado");
      }
    }
    setEstadoFormulario("completando");
  };
  if (integranteID !== null) {
  }
  useEffect(() => {
    if (integranteID !== null) {
      async function fetchData() {
        const data = await getIntegrante(integranteID, token);
        setIntegrante(data.integrante);
        setIntegranteCondiciones(data.condiciones);
      }
      fetchData();
    }
  }, [integranteID, token]);

  useEffect(() => {
    async function fetchData() {
      const categoriasData = await getCategoriasData(categoria);
      console.log(categoria);
      setCategoriaData(categoriasData);
    }
    fetchData();
  }, []);

  return (
    <Fragment>
      <div className="panel-pane pane-titulo">
        <div className="pane-content">
          <h1 className="activities-sidbar">Equipo</h1>
        </div>
      </div>
      <div className="panel-separator"></div>
      <div className="pane-content">
        <div className="row m-b-2">
          <div className="col-md-6">
            <h4 className="activities-sidbar">
              {integranteID
                ? "Modificación Datos del Integrante"
                : "Nuevo integrante"}
            </h4>
          </div>
        </div>
      </div>
      <div className="panel-separator"></div>
      <FormularioIntegrante
        errores={errores}
        convocatoria={convocatoria}
        onSubmit={onSubmit}
        categoria={categoria}
        categoriaData={categoriaData}
        acciones={"integrante"}
        integrante={integrante}
        condiciones={integranteCondiciones}
        setState={() => setInscripcionState("equipo")}
        estadoFormulario={estadoFormulario}
        equipoPresentado={presentado}
        equipo={equipo}
      />
    </Fragment>
  );
};
export default CrearEditarIntegrante;
