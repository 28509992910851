import React, { Fragment, useState, useEffect } from 'react';
import { getCategorias } from '../../api/convocatorias';


const RegistrarEquipoStep1 = (props) => {

    const { convocatoria, changeStep, setCategoria, setAccountState } = props;

    const [categorias, setCategorias] = useState([]);

    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(0);

    useEffect(() => {
        async function fetchData(){
          const data = await getCategorias(convocatoria.id);
          setCategorias(data);
        }
        fetchData();    
    }, [convocatoria]);
    
    const changeCategoria = (event) => {
        setCategoriaSeleccionada(event.target.value);
        setCategoria(event.target.value);
    }


    const nextStep = (step) => {
        if(categoriaSeleccionada === 0){
            console.log("tenes que seleccionar una categoria");
        }
        else{
            changeStep(step);
        }
    }

    return (
        <Fragment>
            <div className="panel-pane pane-texto">
                <div className="pane-content">
                    <div className="wizard m-b-3">
                        <p className="text-muted">Paso 1 de 2</p>
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{width:"50%"}}>
                                <span className="sr-only">Paso 1 de 2</span>
                            </div>
                        </div>
                </div>
            </div>
            <div className="panel-separator"></div>

                <label>Seleccione la opción a la que se presenta el equipo:</label>
                <select className="form-control" onChange={changeCategoria}>
                    <option value={0}>Seleccioná Opción</option>
                   { categorias.length > 0 && categorias.map(categoria => (
                            <option key={categoria.id} value={categoria.id}>{categoria.categoria}</option>
                        ))
                    }
                </select>


                <div className="row m-t-3">
                    <div className="col-sm-12">
                        <button className="btn btn-link" onClick={() => setAccountState('login')}>Cancelar</button>
                        <button onClick={() => nextStep(2)} className="btn btn-success m-l-1">Siguiente</button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default RegistrarEquipoStep1;