import React, { Fragment, useState, useEffect } from 'react';
import CargarArchivos from './CargarArchivos';
import MensajeExito from './MensajeExito';
import { getIntegrantes } from '../../api/convocatorias';


const PresentacionLogeado = (props) => {

    const { setToken, convocatoria, token, llave, cerrarSesion, equipo } = props;

    const [integranteEdit, setIntegranteEdit] = useState(null);

    useEffect(() => {
        // async function fetchData() {
        //     const data = await getIntegrantes(token);
        //     setIntegrantes(data.integrantes);
        //     setEquipo(data.equipo);
        // }
        // fetchData();

    }, [token]);

    // const callBackMessage = () => {
    //     setPresentacionState('carga');
        // async function fetchData() {
        //     const data = await getIntegrantes(token);
        //     setIntegrantes(data.integrantes);
        //     setEquipo(data.equipo);
        // }
        // fetchData();
    // }

    const exitoCambiarClave = () => {
        setToken(null);
    }

    return (
        <Fragment>
            <div className="row">
                <div className="panel-pane pane-redes-sociales pull-right">
                    <div className="pane-content">
                        <div className="section-actions social-share ">
                            <div className="item-list">
                                <ul className="list-inline">

                                    <a onClick={cerrarSesion} data-toggle="tooltip"
                                       data-placement="top" title="Cerrar Sesión"
                                       style={{cursor: 'pointer'}}
                                    >
                                        <i className="fa fa-sign-out"/>
                                    </a>

                                </ul>
                            </div>
                            <div className="clearfix"></div>

                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            {!props.propuestaPresentada ?
                <CargarArchivos
                    setToken={setToken}
                    token={token}
                    llave={llave}
                    equipo={equipo}
                    convocatoria={convocatoria}
                    setPropuestaPresentada={props.setPropuestaPresentada}
                />
            :
                <MensajeExito
                    title={"Presentación de propuestas"}
                    texto={'La propuesta ha sido presentada exitosamente y se ha remitido un mail informando a todo el equipo'}
                />
            }
        </Fragment>
    )
}
export default PresentacionLogeado;