import React, { useState, useEffect } from 'react';
import {
    HashRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import MainContent from './main/MainContent';
import Index from './convocatoria/Index';
import { getConvocatoriasData } from '../api/convocatorias';

const Content = () => {

  const [convocatorias, setConvocatorias] = useState([]);
  const [fetching, setFetching] = useState(true)

  useEffect(() => {
    async function fetchData(){
      const data = await getConvocatoriasData();
      setConvocatorias(data);
      setFetching(false)
    }
    fetchData();    
  }, []);

    return (
        <Router>
          <Switch>
            {
              convocatorias && convocatorias.map(convocatoria => (
                <Route 
                    key={convocatoria.id} 
                    path={convocatoria.metadata.slug}
                    render={(props) =>  <Index convocatoria={convocatoria} {...props}/> }
                />
              ))
            }
            <Route path="/"
                   render={(props) => <MainContent convocatorias={convocatorias} fetching={fetching} {...props}/>}/>
          </Switch>
        </Router>
    )
}
export default Content;
