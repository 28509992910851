import React, { Fragment, useState, useEffect } from 'react';
import RecuperarLlave from './RecuperarLlave';
import IngresarLlave from './IngresarLlave';
import MensajeExito from './MensajeExito';

const PresentacionDeslogeado = (props) => {

    const { convocatoria, setMenuActive, setLlave, setPropuestaPresentada, setEquipo } = props;

    const [accountState, setAccountState] = useState('llave');

    useEffect(() => {
        setMenuActive("IN");
    }, [setMenuActive]);


    const callBackMessage = () => {
        setAccountState('llave');
    }

    return (
        <Fragment>
            {accountState === 'llave' &&
                <IngresarLlave convocatoria={convocatoria} setAccountState={setAccountState}
                               setLlave={setLlave} setEquipo={setEquipo}
                               setPropuestaPresentada={setPropuestaPresentada}/>}
            {accountState === 'recuperar' &&
                <RecuperarLlave convocatoria={convocatoria} setAccountState={setAccountState} />}
            {
                accountState === 'llave-reseteada' &&
                <MensajeExito
                    callBackAceptar={callBackMessage}
                    title={"Llave recuperada"}
                    texto={"La Llave fue recuperada con éxito y enviada al mail del contacto Principal."}
                />
            }
        </Fragment>
    )
}
export default PresentacionDeslogeado;