import React, { Fragment, useState } from 'react';
import { useForm } from "react-hook-form";
import { resetPassword } from '../../api/user';

const RecuperarPassword = (props) => {

    const { setAccountState, convocatoria } = props;

    const btnLikeA = {
        background: 'transparent',
        border: 'none',
        padding: '0!important',
        color: '#069',
        textDecoration: 'underline',
        cursor: 'pointer',
    }

    const { register, errors, handleSubmit } = useForm();
    const [errores, setErrores] = useState([]);
    const [resetearndoPassword, setReseteandoPassword] = useState(false);

    const onSubmit = async (data) => {
        setReseteandoPassword(true)
        const response = await resetPassword({...data, 'convocatoria':convocatoria.id});
        switch (response) {
            case 200:
                setAccountState('password-reseteada')
                break;
            case 404:
                setErrores(["El email y/o número de documento ingresado no está registrado en la convocatoria."])
                break;
            default:
                console.log(`Algo salio mal recuperando la clave. Error: ${response}`);
        }
        setReseteandoPassword(false)
    }

    return (
        <Fragment>
            <div className="panel-pane pane-texto">
                <div className="pane-content">
                    <div className="row m-b-2">
                        <div className="col-md-6">
                            <h4 className="activities-sidbar">Recuperar clave</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div id="errores" aria-live="polite" className="alert alert-danger hidden">
                                <h5>Se han producido los siguientes errores:</h5>
                                <ol>
                                    <li>Ingresá tu usuario</li>
                                    <li>Ingresá tu clave</li>
                                </ol>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-md-10 form-group item-form">
                                        <label>Ingresa el DNI del Contacto Principal</label>
                                        <input 
                                            type="text" 
                                            name="numDoc" 
                                            className="form-control"
                                            ref={register({ required: true })} 
                                        />
                                        {errors.numDoc && errors.numDoc.type === "required" && 
                                        <p className="error text-danger">Ingresá el DNI</p>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-10 form-group item-form">
                                        <label>Ingresa el correo del contacto principal</label>
                                        <input 
                                            type="text" 
                                            name="email" 
                                            className="form-control"
                                            ref={register(
                                                { 
                                                    required: true, 
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: "El correo electrónico tiene un formato no válido"
                                                    },
                                                })} 
                                        />
                                        {errors.email && errors.email.type === "required" && 
                                        <p className="error text-danger">Ingresá un correo electrónico</p>}
                                        {errors.email && errors.email.type === "pattern" && 
                                        <p className="error text-danger">El correo electrónico tiene un formato no válido</p>}
                                    </div>
                                </div>
                                { errores.length > 0 && 
                                    errores.map(error => (
                                        <p key={error} className="error text-danger">{error}</p>
                                    ))
                                }
                                <div className="row">
                                    <div className="col-sm-3">
                                        <button 
                                            type="submit"
                                            className={ 
                                                resetearndoPassword ? 
                                                "btn btn-primary state-loading" :
                                                "btn btn-primary" }
                                        >RECUPERAR</button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-10">
                                        <hr/>
                                    </div>
                                </div>
                            </form>

                            <div className="row">
                                <div className="col-xs-12">

                                    <p><button onClick={() => setAccountState('login')} style={btnLikeA}>Ingresar con mi clave</button></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="panel-separator"></div>
        </Fragment>
    )
}
export default RecuperarPassword;