import React, { Fragment, useEffect } from 'react';
import InscripcionesDeslogeado  from './InscripcionesDeslogeado';
import InscripcionesLogeado from './InscripcionesLogeado';

const Inscripciones = (props) => {

    const { convocatoria, setMenuActive, token, setToken } = props;

    useEffect(() => {
        setMenuActive("IN");
    }, [setMenuActive]);

    return (
        <Fragment>
            { token ? 
            (
                <InscripcionesLogeado 
                    convocatoria={convocatoria}
                    setToken={setToken}
                    token={token}
                />
            ):
            (
                <InscripcionesDeslogeado 
                    convocatoria={convocatoria} 
                    setMenuActive={setMenuActive} 
                    setToken={setToken}
                />
            )
            }
        </Fragment>
    )
}
export default Inscripciones;