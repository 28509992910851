import React, { Fragment, useEffect } from 'react';
import PresentacionDeslogeado from './PresentacionDeslogeado';
import PresentacionLogeado from './PresentacionLogeado';

const Presentacion = (props) => {

    const { convocatoria, setMenuActive, llave, setLlave, equipo, setEquipo,
        propuestaPresentada, setPropuestaPresentada } = props;

    useEffect(() => {
        setMenuActive("ER");
    }, [setMenuActive]);

    const cerrarSesion = () => {
        setLlave(null)
        setEquipo(null)
        setPropuestaPresentada(false)
    }

    return (
        <Fragment>
            {llave ?
                (
                    <PresentacionLogeado
                        convocatoria={convocatoria}
                        setLlave={setLlave}
                        llave={llave}
                        equipo={equipo}
                        propuestaPresentada={propuestaPresentada}
                        setPropuestaPresentada={setPropuestaPresentada}
                        cerrarSesion={cerrarSesion}
                    />
                ) :
                (
                    <PresentacionDeslogeado
                        convocatoria={convocatoria}
                        setMenuActive={setMenuActive}
                        setLlave={setLlave}
                        setEquipo={setEquipo}
                        setPropuestaPresentada={setPropuestaPresentada}
                    />
                )
            }
        </Fragment>
    )
}
export default Presentacion;