import React from 'react';
import {Route, Redirect} from "react-router-dom";

const ProtectedRoute = ({ component: Component, id_etapa, convocatoria, redirectUrl, ...rest }) => {

    const estaVigente = () => {
        const etapa = convocatoria.etapas[id_etapa]
        if (!etapa) return false
        return (convocatoria.anterior && ['LA', 'NO','RE'].includes(id_etapa)) ||
            (!convocatoria.anterior && etapa['habilitada'])
    }

    return (
        <Route {...rest} render={
            props => {
                if (estaVigente()) {
                    return <Component {...rest} {...props} />
                } else {
                    return <Redirect to={
                        {
                            pathname: redirectUrl,
                            state: {
                                from: props.location
                            }
                        }
                    } />
                }
            }
        } />
    )
}

export default ProtectedRoute;