import React, { Fragment, useEffect } from 'react';
import { ingresoABases, descargaDeBases } from '../../api/convocatorias';
import { REACT_APP_BACKEND_URL } from "../../api/utils";

const Bases = (props) => {

    const { convocatoria, setMenuActive } = props;

    useEffect(() => {
        setMenuActive("LA");
        if (convocatoria){
          ingresoABases(convocatoria.id);
        }
    }, [convocatoria, setMenuActive]);


    const contarDescargar = () => {
        descargaDeBases(convocatoria.id);
    }

    return (
        <Fragment>
            <div className="panel-pane pane-separador">
                <div className="pane-content">
                    <div className="downloads panel-pane pane-entity-field pane-node-field-download">
                        <hr/>
                    </div>
                </div>
            </div>
            <div className="panel-separator"></div>
            <div className="panel-pane pane-texto">
                <div className="pane-content">
                    <h4 className="pane-title">
                        Bases del Concurso
                    </h4>
                    {
                        convocatoria.bases ? 
                            (
                              <a href={convocatoria.bases}
                                 onClick={contarDescargar}
                                 target='_blank'
                                 rel='noreferrer noopener'
                              >
                                <button type="button"  className="btn btn-primary m-l-1">
                                DESCARGAR
                                </button>
                              </a>)
                            : 
                            <p>Próximamente.</p>
                    }
                    
                </div>
            </div>
            <div className="panel-separator"></div>
        </Fragment>
    )
}
export default Bases;