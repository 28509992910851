import React, { useEffect, useState } from "react";
import Breadcrumb from "./Breadcrumb";
import Redes from "./Redes";
import AsideMenu from "./AsideMenu";
import Home from "./Home";
import Bases from "./Bases";
import Consultas from "./Consultas";
import Inscripciones from "./Inscripciones";
import Presentacion from "./Presentacion";
import Proximamente from "./Proximamente";
import Notificaciones from "./Notificaciones";
import { Redirect, Route, Switch } from "react-router-dom";
import ProtectedRoute from "../common/ProtectedRoute";
import Resultados from "./Resultados";

const Index = (props) => {
  const { convocatoria } = props;

  const [active, setActive] = useState("title");

  const [token, setToken] = useState(null);

  const [llave, setLlave] = useState(null);

  const [equipo, setEquipo] = useState(null);

  const [propuestaPresentada, setPropuestaPresentada] = useState(false);

  const routes = React.useMemo(() => {
    return [
      {
        id: "LA",
        url: "/bases",
        component: () => (
          <Bases setMenuActive={setActive} convocatoria={convocatoria} />
        ),
      },
      {
        id: "CO",
        url: "/consultas",
        component: () => (
          <Consultas setMenuActive={setActive} convocatoria={convocatoria} />
        ),
      },
      {
        id: "NO",
        url: "/notificaciones",
        component: () =>
          convocatoria.notificaciones.length > 0 ? (
            <Notificaciones
              convocatoria={convocatoria}
              setMenuActive={setActive}
            />
          ) : (
            <Proximamente
              setMenuActive={setActive}
              setToken={setToken}
              menuCode={"NO"}
              title={"Notificaciones"}
            />
          ),
      },
      {
        id: "IN",
        url: "/inscripciones",
        component: () => (
          <Inscripciones
            setMenuActive={setActive}
            token={token}
            setToken={setToken}
            convocatoria={convocatoria}
          />
        ),
      },
      {
        id: "ER",
        url: "/propuestas",
        component: () => {
          return (
            <Presentacion
              setMenuActive={setActive}
              convocatoria={convocatoria}
              menuCode={"ER"}
              llave={llave}
              setLlave={setLlave}
              equipo={equipo}
              setEquipo={setEquipo}
              title={"Propuestas"}
              propuestaPresentada={propuestaPresentada}
              setPropuestaPresentada={setPropuestaPresentada}
            />
          );
        },
      },
      {
        id: "RE",
        url: "/resultados",
        component: () => (
          <Resultados
            setMenuActive={setActive}
            menuCode={"RE"}
            convocatoria={convocatoria}
            title={"Resultados"}
          />
        ),
      },
    ];
  }, [convocatoria, llave, propuestaPresentada, token]);

  return (
    <div
      className={
        "region region-content " + convocatoria.metadata.slug?.substring(1)
      }
    >
      <Breadcrumb convocatoria={convocatoria} />
      <section>
        <div className="container">
          <article className="content_format col-md-8 col-md-push-4">
            <div className="panel-pane pane-titulo">
              <div className="pane-content">
                <h1 className="activities-sidbar">
                  {active === "title" && convocatoria.nombre}
                  {active === "LA" && "Bases del Concurso"}
                  {active === "CO" && "Consultas"}
                  {active === "NO" && "Notificaciones y respuestas a consultas"}
                  {active === "IN" && "Inscripciones"}
                  {active === "ER" && "Propuestas"}
                  {active === "RE" && "Resultados del Concurso"}
                </h1>
              </div>
            </div>
            <div className="panel-separator"></div>
            {active === "title" && <Redes convocatoria={convocatoria} />}
            {active === "LA" && <Redes convocatoria={convocatoria} />}
            <div className="panel-separator"></div>
            <a
              href={"assets/files/InstructivoGeneral.pdf"}
              style={{
                marginTop: "auto",
                marginBottom: "0.7em",
                float: "right",
              }}
            >
              ¿Necesitás ayuda?
            </a>
            <Switch>
              {routes.map((r) => (
                <ProtectedRoute
                  path={`${convocatoria.metadata.slug}${r.url}`}
                  redirectUrl={convocatoria.metadata.slug}
                  id_etapa={r.id}
                  convocatoria={convocatoria}
                  component={r.component}
                  exact
                  key={r.id}
                />
              ))}
              <Route
                path={convocatoria.metadata.slug}
                exact
                component={() => (
                  <Home setMenuActive={setActive} convocatoria={convocatoria} />
                )}
              />
              <Route
                render={() => <Redirect to={convocatoria.metadata.slug} />}
              />
            </Switch>
          </article>
          <AsideMenu menuActive={active} convocatoria={convocatoria} />
        </div>
      </section>
    </div>
  );
};
export default Index;
