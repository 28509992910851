import React, { Fragment } from 'react';

const MensajeExito = (props) => {

    const { callBackAceptar, title, texto } = props;

    return (
        <Fragment>
            <div className="panel-pane pane-texto">
                <div className="pane-content">
                    <div className="row m-b-2">
                        <div className="col-md-6">
                            <h4 className="activities-sidbar">{title}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {texto}
                        </div>
                        { callBackAceptar &&
                            <div className="col-sm-3 m-t-3">
                            <button type="button" onClick={callBackAceptar} className="btn btn-primary">ACEPTAR</button>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default MensajeExito;