import React from 'react';

const MainBreadcrumb = () => {
    return (
        <div className="panel-pane pane-imagen-destacada">
            <div className="pane-content">
                <section 
                    className="jumbotron" 
                    style={{backgroundImage: "url(assets/images/header_agencia_concursos.jpg)"}}>
                    <div className="jumbotron_bar">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <ol className="breadcrumb">
                                        <li>
                                            <a href="/">Inicio</a>
                                        </li>
                                        <li>
                                            <a href="https://www.argentina.gob.ar/ciencia">
                                                Ministerio de Ciencia, Tecnología e Innovación
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.argentina.gob.ar/ciencia/agencia">
                                                Agencia Nacional de Promoción de la Investigación, el Desarrollo Tecnológico y la Innovación 
                                            </a>
                                        </li>
                                        <li className="active">
                                            Concursos Interinstitucionales de Ideas y Propuestas
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="jumbotron_body">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12 col-md-8 col-md-offset-2 text-center">
                                    <p><img src="assets/images/logo-agencia.png" alt="Logo Agencia Nacional de Promoción de la Investigación, el Desarrollo Tecnológico y la Innovación"/> </p>
                                    <h2>Concursos Interinstitucionales de Ideas y Propuestas</h2>
                                    <p>La Agencia I+D+i apoya la realización de concursos de ideas junto con otros organismos de la Administración Pública Nacional e Instituciones públicas y privadas.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </div>
    )
}
export default MainBreadcrumb;
