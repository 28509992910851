import React, { Fragment, useState, useEffect } from 'react';
import { registrarEquipo } from '../../api/convocatorias';
import FormularioIntegrante from './FormularioIntegrante';
import { getCategoriasData } from '../../api/convocatorias';


const RegistrarEquipoStep2 = (props) => {

    const { convocatoria, categoria, setAccountState } = props;
    const [estadoFormulario, setEstadoFormulario] = useState('completando');    
    const [errores, setErrores] = useState([]);
    const [categoriaData, setCategoriaData] = useState([]);  

    const onSubmit = async (data) => {
        setEstadoFormulario('posteando');
        const response = await registrarEquipo(data);
        if(response.error){
            setErrores([response.error]);
            setEstadoFormulario('completando')
        }
        else{
            setAccountState('registerOk');
        }
    }

    useEffect(() => {
        async function fetchData(){
          const data = await getCategoriasData(categoria);          
          setCategoriaData(data);
        }
        console.log(categoria);
        fetchData(); 
    }, []);
    
    return (
        <Fragment>
            <div className="panel-pane pane-texto">
                <div className="pane-content">
                    <div className="wizard m-b-3">
                        <p className="text-muted">Paso 2 de 2</p>
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{width: "100%"}}>
                                <span className="sr-only">Paso 2 de 2</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel-separator"></div>

                <FormularioIntegrante
                    errores={errores}
                    estadoFormulario={estadoFormulario}
                    onSubmit={onSubmit} 
                    categoria={categoria}
                    categoriaData={categoriaData}
                    convocatoria={convocatoria}
                    acciones={"registro"}
                    setState={() => setAccountState('registroCancelado')}
                />
            </div>
        </Fragment>
    )
}
export default RegistrarEquipoStep2;