import React, {Fragment, useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import { buildUrl } from '../../api/utils'

const CargarArchivos = (props) => {

    const { setAccountState, convocatoria, llave, setLlave, equipo } = props;

    const { register, errors, handleSubmit } = useForm();
    const [errores, setErrores] = useState({});
    const [erroresGenerales, setErroresGenerales] = useState([])
    const [archivos, setArchivos] = useState(null)
    const [fetching, setFetching] = useState(false)
    const [confirmo, setConfirmo] = useState(false)
    const [archivosAreValid, setArchivosAreValid] = useState(false)

    const btnLikeA = {
        background: 'transparent',
        border: 'none',
        padding: '0!important',
        color: '#069',
        textDecoration: 'underline',
        cursor: 'pointer',
    }

    const btnDisabled = {
      float: 'right',
      pointerEvents: 'none',
      opacity: '.65'
    }

    // Al cargar el equipo se dimensiona un objeto con ids de configuracion de archivo como keys,
    // y arrays de tamanio igual a la cantidad minima requerida de archivos.
    useEffect(() => {
      if (equipo){
        let newArchivos = {}
        let initialErrores = {}
        equipo.categoria.configuraciones_archivos.forEach(c =>{
          newArchivos[c.id] = Array(c.cantidad_min).fill(null)
          initialErrores[c.id] = []
        })
        setArchivos(newArchivos)
        setErrores(initialErrores)
      }
    }, [equipo])


    // Se checkea si la cantidad de archivos general es valida para presentar la propuesta
    // cada vez que se actualiza el objeto 'archivos'.
    useEffect(() => {
      if (archivos && equipo){
        let areValid = true
        const configs = equipo.categoria.configuraciones_archivos
        configs.forEach((c) => {
          if (c.cantidad_min > archivos[c.id].filter(Boolean).length) { areValid = false }
        })
        setArchivosAreValid(areValid)
      }
    }, [equipo, archivos])


    const postArchivos = (endpoint, data) => {
      fetch(endpoint, {
        method: 'POST',
        body: data
      }).then( response => {
        if (response.ok){
          props.setPropuestaPresentada(true)
        } else {
          setErroresGenerales(['No se pudieron subir los archivos. Intente nuevamente.'])
          setFetching(false)
        }
      }).catch(err => {
        setErroresGenerales(['Hubo un error al subir los archivos. Espere unos minutos e intente nuevamente.'])
        setFetching(false)
      })
    }

    const onFileSelect = (e, config, idx) => {
      const max_size = config.peso_max * 1024 * 1024
      const archivo = Array.from(e.target.files)[0]
      let errores_archivo = []
      let all_errores = {...errores}

      // archivos.forEach((archivo) => {
      const ext = new RegExp(`.(${config.tipo.map(t => t.extension).join('|')})($)`, 'g')
      const validExtension = ext.test(archivo.name)
      if(archivo.size > max_size){
        errores_archivo.push(`El tamaño del archivo no puede superar los ${config.peso_max}Mb`)
      }
      if (!validExtension){
        errores_archivo.push(`El archivo debe tener alguno de los siguientes formatos: ${config.tipo.map(t => t.extension).join(',')}`)
      }
      // })

      if (!errores_archivo.length){
        let newArchivos = {...archivos}
        newArchivos[config.id][idx] = archivo
        setArchivos(newArchivos)
      } else {
        e.target.value = "";
      }
      all_errores[config.id][idx] = errores_archivo
      setErrores(all_errores)
    }

    const onAddFile = (id) => {
      let newArchivos = {...archivos}
      newArchivos[id].push(null)
      setArchivos(newArchivos)
    }

    const onSubmit = () => {
      // TODO: filtrar los 'null' en array y checkear que haya la cantidad minima de archivos

      const confirmacion = window.confirm('Tras presentar esta propuesta, no podrá volver a entregarla nuevamente. ¿Está seguro de continuar?')
      if (confirmacion){
        const endpoint = buildUrl('/presentar_propuesta')
        setFetching(true)
        let all_archivos = []
        Object.keys(archivos).forEach((k) => all_archivos = all_archivos.concat(archivos[k]))
        all_archivos = all_archivos.filter(Boolean) // Filtra todos los 'extra' que agregue el usuario
        let data = new FormData();
        all_archivos.forEach((a, i) => data.append(`propuesta_${i}`, a))
        data.append('llave', llave);
        postArchivos(endpoint, data)
      }
    }

    const onFileRemove = (id, idx) => {
      let newArchivos = {...archivos}
      newArchivos[id].splice(idx, 1)
      setArchivos(newArchivos)
    }

    return (
        <Fragment>
            <div className="panel-separator"></div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='col-md-12'>

              </div>
              { archivos &&
                equipo?.categoria.configuraciones_archivos.map((c) => {
                  return (
                    <React.Fragment key={c.id}>
                      <div className="panel-pane pane-texto col-md-12 m-b-2">
                        <div className="pane-content d-flex space justify-content-between">
                          <h4 className="pane-title m-t-05">{c.titulo}</h4>
                        </div>
                      </div>
                      <div className='col-md-12 m-b-1 p-b-1' style={{ borderBottom: '1px solid lightgrey'}}>
                        {
                          archivos[c.id].map((a, idx) =>
                            <React.Fragment key={idx}>
                              <div className="d-flex justify-content-between align-items-center m-b-1" key={`${c.titulo}-${idx}`}>
                                <div className="">
                                  <div className="">
                                    <div className="item-form">
                                      { a ?
                                        <div  >
                                          <p className='font-weight-bold'>{a.name}</p>
                                        </div>
                                        : 'No hay archivo presentado'}

                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <fieldset>
                                    <label htmlFor={`file-${c.id}-${idx}`} className='m-b-0' style= {fetching ? btnDisabled : {float: "right"}}>
                                      <div className="btn btn-primary">
                                        <i className="icono-arg-nube-subir-datos"/>
                                        ELEGIR ARCHIVO
                                      </div>
                                    </label>
                                    <input id={`file-${c.id}-${idx}`}
                                           type="file"
                                           style={{display: 'none'}}
                                           multiple={false}
                                           onChange={(e) => onFileSelect(e, c, idx)}/>
                                  </fieldset>
                                  <div style={{marginTop: 'auto', marginBottom: 'auto', marginLeft: '2em'}}>
                                    <i className="fa fa-times"
                                       style={
                                         { cursor: 'pointer',
                                           marginTop: '4px',
                                           visibility: idx < c.cantidad_min ? 'hidden' : 'visible'
                                         }}
                                       onClick={() => onFileRemove(c.id, idx)}/>
                                  </div>
                                </div>


                              </div>
                              {
                                errores[c.id] && errores[c.id][idx] &&
                                  errores[c.id][idx].map((e, i) => <p className="error text-danger" key={`error-${i}`}>{e}</p>)
                              }
                            </React.Fragment>
                          )
                        }
                        <button type="button"
                                className={`btn btn-link`}
                                style={{float: 'right'}}
                                onClick={() => onAddFile(c.id)}
                                disabled={c.cantidad_max === archivos[c.id].length}
                        >
                          <i className="fa fa-plus" style={{marginRight: '8px'}}/>
                          ADJUNTAR OTRO ARCHIVO
                        </button>
                      </div>
                    </React.Fragment>)
                })
              }

              {
                erroresGenerales.map((e, i) => <p className="error text-danger" key={`error-${i}`}>{e}</p>)
              }
              <div className="col-md-12 m-b-2">
                <div className='d-flex justify-content-between'>
                  <input
                    id='confirma_terminos'
                    className='m-r-1'
                    type="checkbox"
                    checked={confirmo}
                    onChange={() => setConfirmo(!confirmo)}
                  />
                  <label htmlFor="confirma_terminos">
					Declaro que conozco y acepto la totalidad de los términos de las Bases y Condiciones del Concurso.
                  </label>
                </div>
              </div>
              <button type="submit"
                      className={`btn btn-primary ${fetching ? 'state-loading' : ''}`}
                      style={{float: 'right'}}
                      disabled={fetching || !confirmo || !archivosAreValid }
              >
                PRESENTAR
              </button>
            </form>
            <div className="panel-separator"></div>
        </Fragment>
    )
}
export default CargarArchivos;
