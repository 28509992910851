import React from 'react';
import { Link } from 'react-router-dom';

const AsideMenu = (props) => {

    const { convocatoria, menuActive } = props;

    const estaVigente = (id_etapa) => {
        const etapa = convocatoria.etapas[id_etapa]
        if (!etapa) return false;
        return (convocatoria.anterior && ['LA', 'NO','RE'].includes(id_etapa)) ||
            (!convocatoria.anterior && etapa['habilitada'])
    }


    return (
        <aside className="col-md-4 col-md-pull-8">
            <div className="panel-pane pane-node-book-nav">
                <div className="pane-content">
                    <div id="book-navigation-115053" className="book-navigation">
                        <div className="nav nav-pills nav-stacked">
                            <nav className="page-sidebar">
                                <ul className="nav nav-pills nav-stacked">
                                    <li className={ menuActive === 'title' ? "btn-title active" : "btn-title"}> <Link to={convocatoria.metadata.slug}>{convocatoria.nombre}</Link></li>
                                        <li style={{width: '100%'}} className={`${!estaVigente('LA')&& 'disabled'} ${menuActive === 'LA' ? "active" : ""}`}>
                                            <Link to={convocatoria.metadata.slug + "/bases"}>
                                                Bases del Concurso
                                            </Link>
                                        </li>
                                        <li style={{width: '100%'}} className={`${!estaVigente('CO')&& 'disabled'} ${ menuActive === 'CO' ? "active" : ""}`}>
                                            <Link to={convocatoria.metadata.slug + "/consultas"}>
                                                Consultas
                                            </Link>
                                        </li>
                                        <li style={{width: '100%'}} className={`${!estaVigente('NO')&& 'disabled'} ${menuActive === 'NO' ? "active" : ""}`}>
                                            <Link to={convocatoria.metadata.slug + "/notificaciones"}>
                                                Notificaciones y respuestas a consultas
                                            </Link>
                                        </li>
                                        <li style={{width: '100%'}} className={ `${!estaVigente('IN')&& 'disabled'} ${menuActive === 'IN' ? "active" : ""}`}>
                                            <Link to={convocatoria.metadata.slug + "/inscripciones"}>
                                                Inscripciones
                                            </Link>
                                        </li>
                                        <li style={{width: '100%'}} className={ `${!estaVigente('ER')&& 'disabled'} ${menuActive === 'ER' ? "active" : ""}`}>
                                            <Link to={convocatoria.metadata.slug + "/propuestas"}>
                                                Presentación de propuestas
                                            </Link>
                                        </li>
                                        <li style={{width: '100%'}} className={`${!estaVigente('RE')&& 'disabled'} ${ menuActive === 'RE' ? "active" : ""}`}>
                                            <Link to={convocatoria.metadata.slug + "/resultados"}>
                                                Resultados del Concurso
                                            </Link>
                                        </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    )
}
export default AsideMenu;