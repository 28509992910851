import React from 'react';

const Breadcrumb = (props) => {

    const { convocatoria } = props;

    return (
        <div id="block-system-main" className="block block-system clearfix">
            <div className="container">
                <div className="panel-pane pane-page-breadcrumb row">
                    <div className={`${convocatoria.logo ? 'col-lg-9' : ''} col-sm-12 pane-content`}>
                        <ol className="breadcrumb">
                            <li><a href="/">Inicio</a></li>
                            <li><a href="https://www.argentina.gob.ar/ciencia">Ministerio de Ciencia, Tecnología e Innovación</a></li>
                            <li><a href="https://www.argentina.gob.ar/ciencia/agencia">Agencia Nacional de Promoción de la Investigación, el Desarrollo Tecnológico y la Innovación </a></li>

                            <li><a href="https://cii.agencia.mincyt.gob.ar/"> Concursos Interinstitucionales de Ideas y Propuestas</a></li>
                            <li className="active">{convocatoria.nombre}</li>
                        </ol>
                    </div>
                    { convocatoria.logo &&
                        <div className='col-lg-3 col-sm-12 logo-particular-convocatoria'>
                            <img src={convocatoria.logo} alt={`Logo de ${convocatoria.nombre}`}/>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
export default Breadcrumb;