import React, { Fragment, useState, useEffect } from 'react';
import { borrarIntegrante, presentarEquipo } from '../../api/convocatorias';

const Equipo = (props) => {

    const { setToken, integrantes, setInscripcionState, setIntegranteEdit, 
            recargarIntegrantes, presentado, setPresentado, token, convocatoria } = props;

    const [errores, setErrores] = useState([]);
    const [mensajes, setMensajes] = useState([]);

    const [presentando, setPresentando] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [integranteToDelete, setIntegranteToDelete] = useState(null);

    useEffect(() => {
        integrantes.forEach(integrante => {
            if(integrante.principal){
                setPresentado(integrante.equipo.presentado);
            }
        }); 
    }, [integrantes, setPresentado]);

    const btnLikeA = {
        background: 'transparent',
        border: 'none',
        padding: '0!important',
        color: '#069',
        textDecoration: 'underline',
        cursor: 'pointer',
    }

    const logout = () => {
        setToken(null);
    }

    const verEditarIntegrante = (integrante) => {
        setInscripcionState("formulario");
        setIntegranteEdit(integrante);
    }

    const nuevoIntegrante = () => {
        setInscripcionState("formulario");
        setIntegranteEdit(null);
    }

    const cambiarPassword = () => {
        setInscripcionState("cambiarPassword");
    }


    const confirmBorrarIntegranteModal = async () => {
        const response = await borrarIntegrante({'integrante': integranteToDelete.id}, token);
        if(response.error){
            console.log("error al borrar el integrante")
            setShowDeleteModal(false)
        }
        else{
            recargarIntegrantes();
            setShowDeleteModal(false)
        }
    }

    const cerrarBorrarIntegranteModal = () => {
        setIntegranteToDelete(null);
        setShowDeleteModal(false)
    }

    const borrarIntegranteHandle = async (integrante) => {
        setIntegranteToDelete(integrante);
        setShowDeleteModal(true)
    }

    const registarEquipo = async () => {
        setPresentando(true);
        const response = await presentarEquipo(token);
        if (response.res){
            setPresentado(true);
            setErrores([]);
            setMensajes(['El equipo se prensentó con éxito. Se ha enviado una llave secreta al correo del contacto principal.'])
        }
        else{
            setErrores(response.errores);
            setMensajes([]);
        }
        setPresentando(false);
        setShowModal(false);
    }

    return (
        <Fragment>
            <div className="panel-pane pane-titulo">
                <div className="pane-content">
                        <h1 className="activities-sidbar">Equipo</h1>
                </div>
            </div>
            <div className="panel-separator"></div>
            <div className="row" style={{display: 'block'}}>
            <div className="panel-pane pane-redes-sociales pull-right">
                <div className="pane-content">
                    <div className="section-actions social-share">
                        <div className="item-list">
                            <ul className="list-inline">
                                <li className="first" title="Cambiar clave">
                                    <button style={btnLikeA} onClick={cambiarPassword}>
                                        <i className="icono-arg-login" ></i>
                                    </button>
                                </li>
                                <li title="Salir">
                                    <button style={btnLikeA} onClick={logout}>
                                        <i className="fa fa-sign-out"></i>
                                    </button>
                                </li>
                                { convocatoria.manual_inscripcion &&
                                    <li title="Manual de usuario">
                                        <a
                                          href={convocatoria.manual_inscripcion}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                        >
                                            <i className="fa fa-question-circle-o"></i>
                                        </a>
                                    </li>
                                }
                                </ul>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
            </div>
            <div className="panel-separator"></div>
            <div className="panel-pane pane-separador">
                <div className="pane-content">
                    <div className="downloads panel-pane pane-entity-field pane-node-field-download">
                        <hr />
                    </div>
                </div>
            </div>
            <button 
                type="button" 
                style={{float: "right"}}
                className="btn btn-primary"
                onClick={nuevoIntegrante}
                disabled={ presentado ? "disabled" : ""} 
            >
                NUEVO INTEGRANTE
            </button>
            <div className="panel-separator"></div>
            <div className="panel-pane pane-texto">
                <div className="pane-content">
                    <div className="row m-b-2">
                        <div className="col-md-6">
                            <h4 className="activities-sidbar">Integrantes</h4>
                        </div>
                    </div>
                    <table className="table table-responsive-poncho">
                        <thead>
                            <tr>
                                <th>APELLIDO</th>
                                <th>NOMBRES</th>
                                <th>TIPO DOC</th>
                                <th>NRO DOC</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            { integrantes.map(integrante => (
                                <tr key={integrante.id}>
                                    <td data-label="Apellido">{integrante.apellido}</td>
                                    <td data-label="Nombre">{integrante.nombre}</td>
                                    <td data-label="Tipo Doc">
                                        {integrante.otro_tipo_documento === "" ? 
                                        integrante.tipo_documento : integrante.otro_tipo_documento} 
                                    </td>
                                    <td data-label="Nro Doc">{integrante.numero_documento}</td>
                                    <td data-label="">
                                        <button 
                                            type="button" 
                                            className="btn btn-primary btn-sm"
                                            onClick={() => verEditarIntegrante(integrante)}
                                        >
                                            {presentado ? "VER" : "VER/MODIFICAR"}
                                        </button>
                                    </td>
                                    <td data-label="">
                                        { !integrante.principal && !presentado && 
                                        <i className="fa fa-user-times" style={{cursor: 'pointer'}}
                                            onClick={() => borrarIntegranteHandle(integrante)}></i> }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="panel-separator"></div>
            <div className="panel-pane pane-texto">
                <div className="row m-t-3">
                    <div className="col-sm-12">
                        <button 
                            className="btn btn-primary"
                            onClick={() => setShowModal(true)}
                            disabled={ presentado ? "disabled" : ""} 
                        >
                            PRESENTAR EQUIPO
                        </button>
                    </div>
                </div>
            </div>

            { errores.length > 0 && 
                <div className="alert alert-danger">
                { errores.map(error => (
                    <p key={error}>{error}</p>
                ))}
                </div>
            }

            {mensajes.length > 0 &&
                <div className="alert alert-success">
                { mensajes.map(msj => (
                    <p key={msj}>{msj}</p>
                ))}
                </div>
            }

            <div className={showModal ? "modal fade show" : "modal fade"} id="registrarEquipoModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" onClick={() => setShowModal(false)} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Una vez presentado el equipo no podrá modificarse, agregarse o
                                eliminarse ningún integrante
                            </p>
                            <p>¿Está seguro de presentar al equipo?</p>
                        </div>
                        <div className="modal-footer">
                            <button 
                                type="button" 
                                style={{marginBottom: '0'}}
                                className={ 
                                    presentando ? 
                                    "btn btn-primary state-loading" :
                                    "btn btn-primary" }
                                onClick={registarEquipo}
                            >
                                Confirmar</button>
                            <button 
                                type="button" 
                                className="btn btn-link" 
                                onClick={() => setShowModal(false)}
                                disabled={presentando}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={showDeleteModal ? "modal fade show" : "modal fade"} id="borrarIntegranteModal" tabIndex="-1" role="dialog" aria-labelledby="borrarIntegranteModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" onClick={cerrarBorrarIntegranteModal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Eliminarás el Integrante {integranteToDelete && integranteToDelete.apellido}, 
                            {integranteToDelete && integranteToDelete.nombre} de tu equipo. ¿Estás seguro?</p>
                        </div>
                        <div className="modal-footer">
                            <button 
                                type="button" 
                                className={ 
                                    presentando ? 
                                    "btn btn-primary state-loading" :
                                    "btn btn-primary" }
                                onClick={confirmBorrarIntegranteModal}
                            >
                                Confirmar</button>
                            <button 
                                type="button" 
                                className="btn btn-link" 
                                onClick={cerrarBorrarIntegranteModal}
                                disabled={presentando}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default Equipo;