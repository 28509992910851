import React, { Fragment } from 'react';
import './css/estilos1.css'
import './css/estilos2.css'
import './css/estilos3.css'
import './css/estilos4.css'
import './css/custom.css'
import 'ar-poncho/dist/css/poncho.min.css'
import 'ar-poncho/dist/css/icono-arg.css'
import Header from './components/main/Header';
import Content from './components/Content';
import Footer from './components/main/Footer';

function App() {

  return (
    <Fragment>
      <noscript><iframe title="Script GTM" aria-hidden="true" src="//www.googletagmanager.com/ns.html?id=GTM-P68ZXT" style={{display:'none',visibility:'hidden',height:0,width:0}}></iframe></noscript>
      <h1 className="sr-only"><small>Presidencia de la Nación</small></h1>
      <div id="skip-link"> <a href="#main-content" className="element-invisible element-focusable">Pasar al contenido principal</a></div>
      <Header />
      <Content />
      <Footer />
    </Fragment>
  );
}

export default App;
