import React, { Fragment, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { postConsulta } from '../../api/convocatorias'; 

const Consultas = (props) => {

    const { convocatoria, setMenuActive} = props;

    const [labelEnviada, setLabelEnviada] = useState(false);
    const [submitting, setSubmitting] = useState(false)
    const [consultaText, setConsultaText] = useState('')
    const [email, setEmail] = useState('')

    useEffect(() => {
        setMenuActive("CO");
    }, [setMenuActive]);

    useEffect(() => {
        if (consultaText){
            setLabelEnviada(false)
        }
    }, [consultaText])

    const onSubmit = async (e) => {
        e.preventDefault()
        setLabelEnviada(false);
        setSubmitting(true)
        const response = await postConsulta({
            'consulta':`${consultaText}${email ? ` (${email})` : ''}`,
            'convocatoria': convocatoria.id
        });
        setSubmitting(false)
        setConsultaText('')
        setEmail('')
        if (response){
            setLabelEnviada(true);
        }
    }

    const { register, handleSubmit } = useForm();

    return (
        <Fragment>
            {/**<div className="panel-pane pane-separador">
                <div className="pane-content">
                    <div className="downloads panel-pane pane-entity-field pane-node-field-download">
                        <hr/>
                        <h4 className="pane-title">
                            Descargas
                        </h4>
                        <div className="row row-flex">
                        <div className="col-md-12 col-xs-12 col-sm-6">
                            <p className="text-muted m-b-1">Consultas - Octubre 2020</p> <a href="#.pdf" className="btn btn-primary btn-sm"
                                                                                            download><i className="fa fa-download"></i>&nbsp; Descargar archivo</a></div>
                        <div className="col-md-12 col-xs-12 col-sm-6">
                            <p className="text-muted m-b-1">Consultas - Septiembre 2020</p> <a href="#.pdf" className="btn btn-primary btn-sm"
                                                                                                    download><i className="fa fa-download"></i>&nbsp; Descargar archivo</a></div>
                        </div>
                    </div>  
                </div>
            </div>*/}
            <div className="panel-separator"></div>
            <div className="panel-pane pane-texto">
                <form onSubmit={onSubmit}>
                <div className="pane-content">
                    <label>
                        <p><b>Escriba su consulta:</b></p></label>
                    <textarea className="form-control"
                              name="consulta"
                        required={true}
                              rows="5"
                              onChange={e => setConsultaText(e.target.value)}
                              disabled={submitting}
                              value={consultaText}/>
                    <label className='m-t-3'><p><b><u>Su mail</u> (opcional)</b> - Será utilizado en caso que el Consejo Asesor del concurso decida remitirle una respuesta personalizada.</p></label>
                    <input type='email'
                           className='form-control'
                           onChange={e => setEmail(e.target.value)}
                           disabled={submitting}
                           value={email}
                    />
                    {
                        labelEnviada &&
                        <div className="alert alert-info m-t-2">
                            <div className="media">
                                <div className="media-left">
                                    <i className="fa fa-check-circle fa-fw fa-4x"></i>
                                </div>
                                <div className="media-body">
                                    <h5>Su consulta fue enviada correctamente</h5>
                                    <p className="margin-0">
                                        Será respondida a la brevedad posible.
                                        Revise con frecuencia las respuestas en la opción del menú "Notificaciones y respuestas a consultas"
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="m-y-2">
                        <button type="submit"
                                className={`btn btn-primary ${ submitting ? 'state-loading disabled' : ''}`}>
                            ENVIAR
                        </button>
                    </div>
                </div>
                </form>
            </div>
            <div className="panel-separator"></div>
        </Fragment>
    )
}
export default Consultas;