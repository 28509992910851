import { buildUrl } from './utils'

export const getConvocatoriasData = async () => {
    try{
        const response = await fetch(buildUrl(`/convocatorias/`))
        const result = await response.json();
        return result;
    } catch (error){
        console.log(error);
    }
}


export const postConsulta = async (data) => {
    try{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
        };
        const response = await fetch(buildUrl(`/consultas/`), requestOptions)
        await response.json();
        if (response.status === 201){
            return true
        }
        console.log(`Algo salio mal creando la consulta. Status: ${response.status}`);
        return false;
    } catch (error){
        console.error(`Algo salio mal creando la consulta. Error: ${error}`);
        return false;
    }
}

export const getCategorias = async (convocatoria) => {
    try{
        const response = await fetch(buildUrl(`/categorias/?convocatoria=${convocatoria}`))
        const result = await response.json();
        return result;
    } catch (error){
        console.log(error);
    }
}

export const getCategoriasData = async (categoriaId) => {
    try{
        const response = await fetch(buildUrl(`/categorias/?id=${categoriaId}`))        
        const result = await response.json();
        return result;
    } catch (error){
        console.log(error);
    }
}

export const getSubCategorias = async (categoria) => {
    try{
        const response = await fetch(buildUrl(`/subcategorias/?categoria=${categoria}`))
        const result = await response.json();
        return result;
    } catch (error){
        console.log(error);
    }
}

export const registrarEquipo = async (data) => {
    try{
        const requestOptions = {
            method: 'POST',
            body: data,
        };
        const response = await fetch(buildUrl(`/registrar/`), requestOptions)
        const result = await response.json();
        return result;
    } catch (error){
        console.error(`Algo salio mal registrando al equipo. Error: ${error}`);
        return false;
    }
}

export const getIntegrantes = async (token) => {
    try{
        const requestOptions = {
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        };
        const response = await fetch(buildUrl(`/integrantes/`), requestOptions)
        const result = await response.json();
        return result;
    } catch (error){
        console.log(error);
    }
}

export const getIntegrante = async (id, token) => {
    try{
        const requestOptions = {
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        };
        const response = await fetch(buildUrl(`/integrantes/${id}`), requestOptions)
        const result = await response.json();
        return result;
    } catch (error){
        console.log(error);
    }
}

export const crearIntegrante = async (data, token) => {
    try{
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Authorization': `Token ${token}`,
            },
            body: data,
        };
        const response = await fetch(buildUrl(`/integrante/`), requestOptions)
        const res = await response.json();
        return res
    } catch (error){
        console.error(`Algo salio mal registrando al integrante. Error: ${error}`);
        return false;
    }
}

export const editarIntegrante = async (data, token) => {
    try{
        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Authorization': `Token ${token}`,
            },
            body: data,
        };
        const response = await fetch(buildUrl(`/integrante/`), requestOptions)
        const res = await response.json();
        return res
    } catch (error){
        console.error(`Algo salio mal registrando al integrante. Error: ${error}`);
        return false;
    }
}

export const borrarIntegrante = async (data, token) => {
    try{
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify(data),
        };
        const response = await fetch(buildUrl(`/borrar_integrante/`), requestOptions)
        const res = await response.json();
        return res
    } catch (error){
        console.error(`Algo salio mal eliminando al integrante. Error: ${error}`);
        return false;
    }
}

export const presentarEquipo = async (token) => {
    try{
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify({}),
        };
        const response = await fetch(buildUrl(`/presentar_equipo/`), requestOptions)
        const data = await response.json();
        if (response.status === 200){
            return {'res':true, 'status':response.status, 'errores': []}
        }
        else{
            if (response.status === 400){
                return {'res':false, 'status':response.status, 'errores': data.errores}
            }
        }
        console.log(`Algo salio mal presentando al equipo. Status: ${response.status}`);
        return false;
    } catch (error){
        console.error(`Algo salio mal presentando al equipo. Error: ${error}`);
        return false;
    }
}


export const ingresoABases = async (convocatoria) => {
    try{
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({convocatoria: convocatoria}),
        };
        const response = await fetch(buildUrl(`/ingreso_bases/`), requestOptions)
        const res = await response.json();
        return res
    } catch (error){
        return false;
    }
}

export const descargaDeBases = async (convocatoria) => {
    try{
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({convocatoria: convocatoria}),
        };
        const response = await fetch(buildUrl(`/descarga_bases/`), requestOptions)
        const res = await response.json();
        return res
    } catch (error){
        return false;
    }
}

