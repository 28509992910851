import React, { Fragment, useState, useEffect } from 'react';
import Account from './Account';
import RecuperarPassword from './RecuperarPassword';
import RegistrarEquipo from './RegistrarEquipo';
import MensajeExito from './MensajeExito';

const InscripcionesDeslogeado = (props) => {

    const { convocatoria, setMenuActive, setToken } = props;

    const [accountState, setAccountState] = useState('login');

    useEffect(() => {
        setMenuActive("IN");
    }, [setMenuActive]);


    const callBackMessage = () => {
        setAccountState('login');
    }

    return (
        <Fragment>
            {   accountState === 'login' && 
                <Account convocatoria={convocatoria} setAccountState={setAccountState} setToken={setToken}/>}
            {   accountState === 'recover' &&
                <RecuperarPassword convocatoria={convocatoria} setAccountState={setAccountState}/>}
            {   accountState === 'register' &&
                <RegistrarEquipo convocatoria={convocatoria} setAccountState={setAccountState}/>}
            {   accountState === 'registerOk' &&
                <MensajeExito 
                    callBackAceptar={callBackMessage}
                    title={"Paso inicial de inscripción exitoso"}
                    texto={"Se ha iniciado el proceso de incripción. Se enviará la clave de ingreso al mail del contacto principal."}
                />
            }
            {
                accountState === 'password-reseteada' &&
                <MensajeExito 
                    callBackAceptar={callBackMessage}
                    title={"Clave recuperada"}
                    texto={"La clave fue recuperada con éxito y enviada al mail del contacto Principal."}
                />
            }
            {
                accountState === 'registroCancelado' &&
                <MensajeExito 
                    callBackAceptar={callBackMessage}
                    title={"Registro cancelado"}
                    texto={"El registro fue cancelado. No se registró el Equipo."}
                />
            }
        </Fragment>
    )
}
export default InscripcionesDeslogeado;
