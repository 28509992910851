import React, { Fragment, useState , useEffect} from 'react';
import Equipo from './Equipo';
import CrearEditarIntegrante from './CrearEditarIntegrante';
import CambiarPassword from './CambiarPassword';
import MensajeExito from './MensajeExito';
import { getConvocatoriasData, getIntegrantes } from '../../api/convocatorias';


const InscripcionesLogeado = (props) => {

    const { setToken, convocatoria, token } = props;

    const [inscripcionState, setInscripcionState] = useState('equipo');
    const [integranteEdit, setIntegranteEdit] = useState(null);

    const [integrantes, setIntegrantes] = useState([]);
    const [categoriaData, setCategorias] = useState([]); 
    const [equipo, setEquipo] = useState(null);

    const [presentado, setPresentado] = useState(false);

    useEffect(() => {
        async function fetchData(){
            const data = await getIntegrantes(token);
            setIntegrantes(data.integrantes);
            setEquipo(data.equipo);
        }
        fetchData();

    }, [token]);

    const callBackMessage = () => {
        setInscripcionState('equipo');
        async function fetchData(){
            const data = await getIntegrantes(token);
            setIntegrantes(data.integrantes);
            setEquipo(data.equipo);
        }
        fetchData();
    }

    const exitoCambiarClave = () => {
        setToken(null);
    }

    return (
        <Fragment>
            { inscripcionState === 'equipo' && 
                <Equipo 
                    presentado={presentado}
                    setPresentado={setPresentado}
                    setToken={setToken} 
                    token={token}
                    setInscripcionState={setInscripcionState}
                    setIntegranteEdit={setIntegranteEdit}
                    integrantes={integrantes}
                    recargarIntegrantes={callBackMessage}
                    convocatoria={convocatoria}
                />
            }
            { inscripcionState === 'formulario' && 
                <CrearEditarIntegrante 
                    presentado={presentado}
                    setToken={setToken} 
                    token={token}
                    categoriasData={categoriaData}
                    integranteID={integranteEdit && integranteEdit.id} 
                    convocatoria={convocatoria}
                    setInscripcionState={setInscripcionState}
                    categoria={equipo.categoria.id}
                    equipo={equipo}
                />
            }
            { inscripcionState === 'cambiarPassword' && 
                <CambiarPassword 
                    token={token}
                    setInscripcionState={setInscripcionState}
                />
            }
            {
                inscripcionState === 'integrante-creado' &&
                <MensajeExito 
                    callBackAceptar={callBackMessage}
                    title={"Registro de nuevo integrante"}
                    texto={"El nuevo integrante fue registrado con éxito al equipo."}
                />
            }
            {
                inscripcionState === 'integrante-editado' &&
                <MensajeExito 
                    callBackAceptar={callBackMessage}
                    title={"Modificación Datos del Integrante"}
                    texto={"Los datos del Integrante "+integranteEdit.apellido+", "+integranteEdit.nombre+" fueron modificados con éxito."}
                />
            }
            {
                inscripcionState === 'password-actualizada' &&
                <MensajeExito 
                    callBackAceptar={exitoCambiarClave}
                    title={"Cambio de clave"}
                    texto={"La clave fue modificada con éxito."}
                />
            }
        </Fragment>
    )
}
export default InscripcionesLogeado;