import React, { Fragment } from 'react';
import MainBreadcrumb from './MainBreadcrumb'
import Convocatorias from './Convocatorias'

const MainContent = (props) => {

    const { convocatorias, fetching } = props;
    return (
        <Fragment>
            <MainBreadcrumb />
            <Convocatorias convocatorias={convocatorias} fetching={fetching}/>
        </Fragment>
    )
}
export default MainContent;