import React from 'react';

const Footer = () => {
    return (
        <footer className="main-footer celeste">
            <div style={{display: 'none'}}>
                {process.env.REACT_APP_ENVIROMENT}
            </div>
            <div className="container">
            <section>
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <section className="block block-block clearfix"> <img className="image-responsive" src="assets/images/logo_argentina_unida.svg" alt="Argentina Unida"/>
                    </section>
                    <h5>Redes sociales del área</h5>
                    <div className="social-share">
                        <ul className="list-inline">
                            <li><a href="https://www.facebook.com/agenciaidiar" target="_blank" rel="noopener noreferrer"><span className="sr-only">Facebook</span><i className="icono-arg-facebook-f-" aria-hidden="true"></i></a></li>
                            <li><a href="https://twitter.com/agenciaidiar" target="_blank" rel="noopener noreferrer"><span className="sr-only">Twitter</span><i className="icono-arg-twitter-pajaro" aria-hidden="true"></i></a></li>
                            <li><a href="https://www.instagram.com/agenciaidiar/" target="_blank" rel="noopener noreferrer"><span className="sr-only">Instagram</span><i className="icono-arg-instagram" aria-hidden="true"></i></a></li>
                            <li><a href="https://www.youtube.com/channel/UCY9sEhPzO38ygCkWFtlxVDg" target="_blank" rel="noopener noreferrer"><span className="sr-only">Youtube</span><i className="icono-arg-youtube" aria-hidden="true"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6">
                    <div className="region region-footer2">
                        <section id="block-menu-menu-footer-2" className="block block-menu clearfix">
                            <ul className="menu nav">
                                <li className="first leaf" style={{width: '100%'}}><a href="https://www.argentina.gob.ar/turnos">Turnos</a></li>
                                <li className="leaf" style={{width: '100%'}}><a href="https://www.argentina.gob.ar/organismos">Organismos del Estado</a></li>
                                <li className="last leaf" style={{width: '100%'}}><a href="https://www.argentina.gob.ar/jefatura/mapa-del-estado">Mapa del estado</a></li>
                            </ul>
                        </section>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6">
                    <div className="region region-footer3">
                        <section id="block-menu-menu-footer-3" className="block block-menu clearfix">
                            <ul className="menu nav">
                                <li className="first leaf" style={{width: '100%'}}><a href="https://www.argentina.gob.ar/normativa">Leyes argentinas</a></li>
                                <li className="leaf" style={{width: '100%'}}><a href="https://www.argentina.gob.ar/acerca">Acerca de este sitio</a></li>
                                <li className="last leaf" style={{width: '100%'}}><a href="https://www.argentina.gob.ar/terminos-y-condiciones">Términos y condiciones</a></li>
                            </ul>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    </div>
    </footer>
        
    )
}
export default Footer;
