import { buildUrl } from './utils'

export const cambiarPassword = async (data, token) => {
    try{
        const requestOptions = {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify(data),
        };
        const response = await fetch(buildUrl(`/change_password/`), requestOptions)
        if (response.status === 204){
            return {'status':response.status}
        }
        const res = await response.json();
        return {'status':response.status, 'data': res}
    } catch (error){
        console.error(`Algo salio mal cambiando la conrtaseña. Error: ${error}`);
        return false;
    }
}

export const resetPassword = async (data) => {
    try{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(data),
        };
        const response = await fetch(buildUrl(`/reset_password/`), requestOptions)
        await response.json();
        return response.status
    } catch (error){
        console.error(`Algo salio mal reseteando la clave. Error: ${error}`);
        return false;
    }
}

