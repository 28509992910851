import React from 'react';

const Header = () => {
    return (
        <header>
            <nav 
                className="navbar navbar-top navbar-default bg-celeste-argentina" 
                role="navigation"
                style={{padding: 0}}
            >
                <div className="container">
                    <div style={{width: '100%'}}>
                        <div className="navbar-header">
                            <a 
                                className="navbar-brand" 
                                href="https://www.argentina.gob.ar" 
                                aria-label="Argentina.gob.ar Presidencia de la Nación"
                            > 
                                <img 
                                    src="../assets/images/logo.svg" 
                                    alt="Argentina.gob.ar" 
                                    height="50" /> 
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}
export default Header;