import React from 'react';

const Redes = (props) => {

    const { convocatoria } = props;
    return (
        <div className="panel-pane pane-redes-sociales">
            <div className="pane-content">
                <div className="section-actions social-share">
                    <p>Compartir en<br/> redes sociales</p>
                    <div className="item-list">
                        <ul className="list-inline">
                            <li className="first"><a href={"http://www.facebook.com/sharer/sharer.php?u=https://cii.agencia.mincyt.gob.ar" + convocatoria.metadata.slug +"&amp;title=Concurso de Ideas"} target="_blank" rel="noopener noreferrer"><span className="sr-only">Compartir en Facebook</span><i className="icono-arg-facebook-f-" aria-hidden="true"></i></a></li>
                            <li><a href={"https://twitter.com/share?url=https://cii.agencia.mincyt.gob.ar" + convocatoria.metadata.slug +"&text=Concurso&amp;de&amp;Ideas"} target="_blank" rel="noopener noreferrer"><span className="sr-only">Compartir en Twitter</span><i className="icono-arg-twitter-pajaro" aria-hidden="true"></i></a></li>
                            <li>
                                <a href={"https://www.linkedin.com/shareArticle?mini=true&amp;url=https://cii.agencia.mincyt.gob.ar" + convocatoria.metadata.slug} target="_blank" rel="noopener noreferrer"><span className="sr-only">Compartir en Linkedin</span><i className="icono-arg-linkedin-in" aria-hidden="true"></i></a>
                            </li>
                            <li>
                                <a href={"https://web.whatsapp.com/send?text=https://cii.agencia.mincyt.gob.ar" + convocatoria.metadata.slug} target="_blank" rel="noopener noreferrer" id="linkWA"><span className="sr-only">Compartir en Whatsapp</span><i className="icono-arg-whatsapp-telefono" aria-hidden="true"></i></a>
                            </li>
                            <li className="last"><a href={"https://t.me/share/url?url=https://cii.agencia.mincyt.gob.ar" + convocatoria.metadata.slug} target="_blank" rel="noopener noreferrer" id="linkTG1"><span className="sr-only">Compartir en Telegram</span><i className="icono-arg-telegram-avion" aria-hidden="true"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Redes;