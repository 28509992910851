import React, { Fragment, useState } from 'react';
import RegistrarEquipoStep1 from './RegistrarEquipoStep1';
import RegistrarEquipoStep2 from './RegistrarEquipoStep2';


const RegistrarEquipo = (props) => {

    const [step, setStep] = useState(1);

    const [categoria, setCategoria] = useState(null);

    const { convocatoria, setAccountState } = props;


    return (
        <Fragment>
            { step === 1 &&  
                <RegistrarEquipoStep1 
                    convocatoria={convocatoria} 
                    changeStep={setStep}
                    setCategoria={setCategoria}
                    setAccountState={setAccountState}
                />
            }
            { step === 2 &&  
                <RegistrarEquipoStep2
                    convocatoria={convocatoria}  
                    changeStep={setStep}
                    categoria={categoria}
                    setAccountState={setAccountState}
                />
            }
        </Fragment>
    )
}
export default RegistrarEquipo;