import React, {Fragment, useEffect} from 'react';
import {REACT_APP_BACKEND_URL} from "../../api/utils";

const Resultados = (props) => {

  const { convocatoria, setMenuActive } = props;

  useEffect(() => {
    setMenuActive("RE");
  }, [setMenuActive]);

  return (
    <Fragment>
      <div className="panel-separator"></div>
      <div className="panel-pane pane-texto">
        <div className="pane-content">
          {convocatoria.resultados.map(res => (
            <p key={res.filename}>
              <strong>{res.fecha}  </strong>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={REACT_APP_BACKEND_URL+res.link}
              >
                {res.filename_text}
              </a>
            </p>
          ))}
        </div>
      </div>
      <div className="panel-separator"></div>
    </Fragment>
  );
};

export default Resultados;