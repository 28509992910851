import React, { Fragment, useState } from 'react';
import { useForm } from "react-hook-form";
import { cambiarPassword } from '../../api/user';


const CambiarPassword = (props) => {

    const { setInscripcionState, token } = props;

    const [errores, setErrores] = useState([]);

    const { register, errors, handleSubmit, getValues } = useForm();


    const onSubmit = async (data) => {
        const response = await cambiarPassword(data, token);
        switch (response.status) {
            case 204:
                setInscripcionState('password-actualizada')
                break;
            case 400:
                setErrores([])
                if(response.data.new_password.includes(
                    "This password is too short. It must contain at least 8 characters.")){
                    setErrores(['La clave es demasiado corta. Debe contener al menos 8 caracteres.'])
                }
                break;
            default:
                break;
        }
    }


    return (
        <Fragment>
            <div className="panel-pane pane-titulo">
                <div className="pane-content">
                        <h1 className="activities-sidbar">Cambiar clave</h1>
                </div>
            </div>
            <div className="panel-separator"></div>
            <div className="panel-pane pane-redes-sociales">
                <div className="pane-content">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-10 form-group item-form">
                                <label>Ingresa la clave actual</label>
                                <input 
                                    autoComplete="off"
                                    type="password" 
                                    name="old_password" 
                                    className="form-control"
                                    ref={register({ required: true })}
                                />
                                {errors.old_password && errors.old_password.type === "required" && 
                                <p className="error text-danger">Ingresá la clave actual</p>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-10 form-group item-form">
                                <label>Ingresa la clave nueva</label>
                                <input 
                                    autoComplete="off"
                                    type="password" 
                                    name="new_password" 
                                    className="form-control"
                                    ref={register({ required: true })}
                                />
                                {errors.new_password && errors.new_password.type === "required" && 
                                <p className="error text-danger">Ingresá la nuevo clave</p>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-10 form-group item-form">
                                <label>Ingresa la clave actual nuevamente</label>
                                <input 
                                    autoComplete="off"
                                    type="password" 
                                    name="new_password2" 
                                    className="form-control"
                                    ref={register({ 
                                        required: "Ingresa nuevamente la nueva clave", 
                                        validate: (value) => {
                                            if (value === getValues()["new_password"]) {
                                                return true;
                                            } else {
                                                return "Las claves no coinciden";
                                            }
                                        }, 
                                    })}
                                />
                                {errors.new_password2 && 
                                <p className="error text-danger">{errors.new_password2.message}</p>}
                            </div>
                        </div>
 
                        <div className="row">
                            <div className="col-xs-10">
                                <hr/>
                            </div>
                        </div>
                    

                        <div className="row">
                            <div className="col-xs-12">
                                <button onClick={() => setInscripcionState('equipo')} className="btn btn-link">CANCELAR</button>
                                <button type="submit" className="btn btn-primary">CAMBIAR CLAVE</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            { errores.length > 0 && 
                <div className="alert alert-danger">
                { errores.map(error => (
                    <p key={error}>{error}</p>
                ))}
                </div>
            }
            <div className="panel-separator"></div>
        </Fragment>
    )
}
export default CambiarPassword;