import React, { Fragment, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { getSubCategorias } from "../../api/convocatorias";
import { ErrorMessage } from "@hookform/error-message";
import { paises, provincias } from "../common/data";

const FormularioIntegrante = (props) => {
  const {
    condiciones,
    categoria,
    categoriaData,
    acciones,
    equipoPresentado,
    equipo,
    integrante,
    setState,
    estadoFormulario,
    errores,
    convocatoria,
  } = props;

  const [subCategorias, setSubCategorias] = useState([]);
  const [tipoDoc, setTipoDoc] = useState("");
  const [archivos, setArchivos] = useState({});
  const [esContactoPrincipal, setEsContactoPrincipal] = useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [paisElegido, setPaisElegido] = useState("");
  const [inputError, setInputError] = useState(false);
  const [fileInputError, setFileInputError] = useState(false);
  const [inputErrorMsg, setInputErrorMsg] = useState("");

  useEffect(() => {
    if (equipo && integrante) {
      setEsContactoPrincipal(
        equipo.dni_contacto_principal === integrante.numero_documento
      );
    }
  }, [equipo, integrante]);

  useEffect(() => {
    const setUpArchivos = (integrante) => {
      let newArchivos = {};
      if (integrante?.campos_adicionales) {
        integrante.campos_adicionales.forEach((c) => {
          if (c.campo_adicional_convocatoria?.campo_adicional.tipo === "AR") {
            newArchivos[c.campo_adicional_convocatoria.id] = c.archivo;
          }
        });
      }
      if (integrante?.campos_adicionales_categoria) {
        integrante.campos_adicionales_categoria.forEach((c) => {
          if (c.campo_adicional_categoria?.campo_adicional.tipo === "AR") {
            newArchivos[c.campo_adicional_categoria.id] = c.archivo;
          }
        });
      }
      return newArchivos;
    };
    setArchivos(setUpArchivos(integrante));
  }, [integrante]);

  const onSubmit = (data) => {
    let fd = new FormData();
    fd.append("convocatoria", convocatoria.id);
    fd.append("categoria", categoria);
    if (integrante) {
      fd.append("integrante", integrante.id);
    }
    Object.entries(data).forEach(([k, v]) => {
      if (v === undefined) {
        v = "";
      }
      fd.append(k, v);
    });
    if (
      categoriaData[0].campos_adicionales &&
      categoriaData[0].campos_adicionales.length > 0
    ) {
      if (convocatoria.campos_adicionales) {
        convocatoria.campos_adicionales.forEach((c) => {
          fd.delete(`adicional_${c.id}`);
        });
      }

      categoriaData[0].campos_adicionales.forEach((c) => {
        if (c.campo_adicional.tipo === "AR") {
          fd.delete(`adicional_${c.id}`);
          // Pisa el valor del form (filename) con el objeto File real
          fd.append(`adicional_${c.id}`, archivos[c.id]);
        }
      });
    } else if (convocatoria.campos_adicionales) {
      convocatoria.campos_adicionales.forEach((c) => {
        if (c.campo_adicional.tipo === "AR") {
          fd.delete(`adicional_${c.id}`);
          // Pisa el valor del form (filename) con el objeto File real
          fd.append(`adicional_${c.id}`, archivos[c.id]);
        }
      });
    }
    props.onSubmit(fd);
  };

  const changeTipoDoc = (event) => {
    setTipoDoc(event.target.value);
  };
  const changePaisElegido = (event) => {
    setPaisElegido(event.target.value);
  };

  useEffect(() => {
    async function fetchData() {
      const data = await getSubCategorias(categoria);
      setSubCategorias(data);
    }
    fetchData();
    if (integrante) {
      setTipoDoc(integrante.tipo_documento);
      setPaisElegido(integrante.pais);
    }
  }, [categoria, integrante]);

  const { register, errors, handleSubmit, control, reset, getValues } =
    useForm();

  const asterisco = (
    <p style={{ display: "inline-block", color: "red", marginBottom: "0" }}>
      *
    </p>
  );

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6 form-group item-form">
            <label>Apellidos {asterisco}</label>
            <input
              type="text"
              name="lastname"
              className="form-control"
              defaultValue={integrante && integrante.apellido}
              ref={register({ required: true, maxLength: 64 })}
              disabled={equipoPresentado ? "disabled" : ""}
            />
            {errors.lastname && errors.lastname.type === "required" && (
              <p className="error text-danger">Ingresá el apellido</p>
            )}
            {errors.lastname && errors.lastname.type === "maxLength" && (
              <p className="error text-danger">
                El apellido no puede tener mas de 64 caracteres
              </p>
            )}
          </div>

          <div className="col-md-6 form-group item-form">
            <label>Nombres {asterisco}</label>
            <input
              type="text"
              name="name"
              className="form-control"
              id="nombre"
              defaultValue={integrante && integrante.nombre}
              ref={register({ required: true, maxLength: 64 })}
              disabled={equipoPresentado ? "disabled" : ""}
            />
            {errors.name && errors.name.type === "required" && (
              <p className="error text-danger">Ingresá el nombre</p>
            )}
            {errors.name && errors.name.type === "maxLength" && (
              <p className="error text-danger">
                El nombre no puede tener mas de 64 caracteres
              </p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 form-group item-form">
            <label>Tipo de Documento {asterisco}</label>
            <fieldset disabled={equipoPresentado || esContactoPrincipal}>
              <select
                name="tipodoc"
                className="form-control"
                onChange={changeTipoDoc}
                ref={register({ required: true })}
              >
                <option value="">Seleccioná un tipo de documento</option>
                <option
                  value="DNI"
                  selected={integrante?.tipo_documento === "DNI"}
                >
                  DNI
                </option>
                <option
                  value="PAS"
                  selected={integrante?.tipo_documento === "PAS"}
                >
                  Pasaporte extranjero
                </option>
                <option
                  value="OT"
                  selected={integrante?.tipo_documento === "OT"}
                >
                  Otro
                </option>
              </select>
            </fieldset>

            {errors.tipodoc && errors.tipodoc.type === "required" && (
              <p className="error text-danger">
                Debe seleccionar un tipo de documento
              </p>
            )}
          </div>
          <div className="col-md-6 form-group item-form">
            <label>Número de documento {asterisco}</label>
            <input
              type={tipoDoc === "DNI" ? "number" : "text"}
              className="form-control"
              name="numDoc"
              defaultValue={integrante && integrante.numero_documento}
              ref={register({ required: true })}
              readOnly={equipoPresentado || esContactoPrincipal}
            />
            {errors.numDoc && errors.numDoc.type === "required" && (
              <p className="error text-danger">
                Debe ingresar el número de documento
              </p>
            )}
          </div>
        </div>

        <label>Otro tipo de Documento</label>
        <input
          type="text"
          readOnly={equipoPresentado ? true : tipoDoc !== "OT"}
          name="otroDocumento"
          className="form-control"
          maxLength="100"
          defaultValue={
            integrante &&
            integrante.tipo_documento === "OT" &&
            integrante.otro_tipo_documento
              ? integrante.otro_tipo_documento
              : ""
          }
          ref={register({ required: tipoDoc === "OT" })}
        />
        {errors.otroDocumento && errors.otroDocumento.type === "required" && (
          <p className="error text-danger">
            Debe ingresar el tipo de documento
          </p>
        )}

        <label>Dirección de correo electrónico {asterisco}</label>
        <input
          type="email"
          name="email"
          className="form-control"
          disabled={equipoPresentado ? "disabled" : ""}
          defaultValue={integrante && integrante.mail}
          ref={register({
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "El correo electrónico tiene un formato no válido",
            },
          })}
        />
        {errors.email && errors.email.type === "required" && (
          <p className="error text-danger">Ingresá un correo electrónico</p>
        )}
        {errors.email && errors.email.type === "pattern" && (
          <p className="error text-danger">
            El correo electrónico tiene un formato no válido
          </p>
        )}

        <div className="row">
          <div className="col-md-6 form-group item-form">
            <label>Domicilio {asterisco}</label>
            <input
              type="text"
              name="direccion"
              className="form-control"
              defaultValue={integrante && integrante.domicilio}
              ref={register({ required: true })}
              disabled={equipoPresentado ? "disabled" : ""}
            />
            {errors.direccion && errors.direccion.type === "required" && (
              <p className="error text-danger">Ingresá la dirección</p>
            )}
          </div>
          <div className="col-md-6 form-group item-form">
            <label>Localidad {asterisco}</label>
            <input
              type="text"
              name="localidad"
              className="form-control"
              defaultValue={integrante && integrante.localidad}
              ref={register({ required: true })}
              disabled={equipoPresentado ? "disabled" : ""}
            />
            {errors.localidad && errors.localidad.type === "required" && (
              <p className="error text-danger">Ingresá la localidad</p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 form-group item-form">
            <label>País {asterisco}</label>
            <select
              className="form-control"
              name="pais"
              defaultValue=""
              onChange={changePaisElegido}
              ref={register({ required: true })}
              disabled={equipoPresentado ? "disabled" : ""}
            >
              <option value="">Seleccioná un país</option>
              <optgroup label="Países">
                {paises.map((pais) => (
                  <option
                    key={pais}
                    value={pais}
                    selected={integrante && integrante.pais === pais}
                  >
                    {pais}
                  </option>
                ))}
              </optgroup>
            </select>
            {errors.pais && errors.pais.type === "required" && (
              <p className="error text-danger">Seleccione un pais</p>
            )}
          </div>

          <div className="col-md-6 form-group item-form">
            <label>Provincia {asterisco}</label>
            <select
              className="form-control"
              name="provincia"
              ref={register({ required: true })}
              disabled={
                equipoPresentado || paisElegido !== "Argentina"
                  ? "disabled"
                  : ""
              }
            >
              <option value="">Seleccioná una provincia</option>

              {/* {integrante.pais === "Argentina" ? (   */}
              {paisElegido === "Argentina" ? (
                <optgroup label="Provincias">
                  {provincias.map((provincia) => (
                    <option
                      key={provincia}
                      value={provincia}
                      selected={
                        integrante && integrante.provincia === provincia
                      }
                    >
                      {provincia}
                    </option>
                  ))}
                </optgroup>
              ) : (
                <optgroup label="Provincias">
                  <option key="-" value="-" selected="selected">
                    -
                  </option>
                </optgroup>
              )}
            </select>
            {errors.provincia && errors.provincia.type === "required" && (
              <p className="error text-danger">Seleccione una provincia</p>
            )}
          </div>

          <div className="col-md-6 form-group item-form">
            <fieldset>
              <legend>Teléfono móvil {asterisco}</legend>
              <div className="row">
                <div className="col-sm-12">
                  {paisElegido === "Argentina" && (
                    <p className="help-block" id="help_telefono_movil">
                      Incluí el{" "}
                      <a
                        href="https://www.argentina.gob.ar/pais/codigo-telefonia"
                        aria-label="Ingresá código de área. Si no lo sabés consultá en este enlace"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        código de área
                      </a>{" "}
                      de tu localidad (para Argentina)
                    </p>
                  )}
                </div>
                {paisElegido === "Argentina" && (
                  <div className="form-group col-xs-12 col-sm-5 item-form">
                    <div className="input-group">
                      <span className="input-group-addon"> 0</span>
                      <input
                        aria-label="Código de area de teléfono móvil"
                        className="form-control"
                        name="codigoArea"
                        defaultValue={
                          integrante &&
                          integrante.telefono.split("-").length > 1
                            ? integrante.telefono.split("-")[0].trim()
                            : null
                        }
                        ref={register({
                          required: paisElegido === "Argentina",
                        })}
                        type="number"
                        disabled={
                          equipoPresentado || paisElegido !== "Argentina"
                            ? "disabled"
                            : ""
                        }
                      />
                    </div>
                    {errors.codigoArea &&
                      errors.codigoArea.type === "required" && (
                        <p className="error text-danger">
                          Ingresá el código de área
                        </p>
                      )}
                  </div>
                )}
                <div className="form-group col-xs-12 col-sm-7 item-form">
                  <div
                    className={
                      paisElegido === "Argentina" ? "input-group" : "input"
                    }
                  >
                    {paisElegido === "Argentina" && (
                      <span className="input-group-addon">15</span>
                    )}
                    <input
                      aria-label="número de teléfono móvil"
                      className="form-control"
                      name="numeroCelular"
                      defaultValue={
                        integrante &&
                        (integrante.telefono.split("-").length > 1
                          ? integrante.telefono.split("-")[1].trim()
                          : integrante.telefono.trim())
                      }
                      ref={register({ required: true })}
                      type="number"
                      disabled={equipoPresentado ? "disabled" : ""}
                    />
                  </div>
                  {errors.numeroCelular &&
                    errors.numeroCelular.type === "required" && (
                      <p className="error text-danger">
                        Ingresá tu número de teléfono móvil
                      </p>
                    )}
                </div>
              </div>
            </fieldset>
          </div>
        </div>

        <div className="row" id="camposAdicionales">
          {
            // Si NO hay campos personalizados en la categoria, muestra campos personalizados de la convocatoria
            (categoriaData && categoriaData.length <= 0) ||
            categoriaData[0].campos_adicionales.length <= 0
              ? convocatoria.campos_adicionales
                ? convocatoria.campos_adicionales.map((c) => (
                    <div
                      className="col-md-6 form-group item-form"
                      key={c.campo_adicional.id}
                    >
                      {c.campo_adicional.tipo === "TXT" ? (
                        <>
                          <label>
                            {c.campo_adicional.titulo}{" "}
                            {c.requerido ? asterisco : ""}
                          </label>
                          <input
                            type="text"
                            key={`adicional_${c.id}`}
                            name={`adicional_${c.id}`}
                            className="form-control"
                            defaultValue={
                              integrante?.campos_adicionales?.find((ca) => {
                                return (
                                  ca.campo_adicional_convocatoria?.id === c.id
                                );
                              })?.valor || ""
                            }
                            ref={register({
                              required: c.requerido,
                              maxLength: 128,
                            })}
                            onBlur={(e) => {
                              if (e.target.value.length > 128) {                              
                                setInputError(true);
                              } else {
                                setInputError(false);
                              }
                            }}
                            style={inputError ? { borderColor: "red", borderWidth: "3px" } : {}}
                            disabled={equipoPresentado ? "disabled" : ""}
                          />

                          {errors[`adicional_${c.id}`] &&
                            errors[`adicional_${c.id}`].type === "required" && (
                              <p className="error text-danger">
                                El campo es obligatorio
                              </p>
                            )}
                            {inputError && 
                              <p className="error text-danger">
                                El texto no puede superar los 128 caracteres
                              </p>
                            }
                        </>
                        // Si el campo es de tipo archivo
                      ) : (
                        <fieldset key={`adicional_conv_${c.id}`}>
                          <label
                            key={`adicional_conv_${c.id}`}
                            style={{ display: "block" }}
                          >
                            {c.campo_adicional.titulo}{" "}
                            {c.requerido ? asterisco : ""}
                          </label>
                          <label htmlFor={`adicional_${c.id}`}
                           style={{marginBottom: "0!important" }}>
                            <div
                              className={`btn btn-primary ${
                                equipoPresentado ? "disabled" : ""
                              }`}
                              style={{marginBottom: 0 }}
                            >
                              <i className="icono-arg-nube-subir-datos" />
                              ELEGIR ARCHIVO 1
                            </div>
                            {archivos[c.id] && (
                              <div className="row">
                                <div className="col-md-10  item-form">
                                  {archivos[c.id] instanceof File
                                    ? archivos[c.id].name
                                    : "Ya fue subido un archivo"}
                                </div>
                                {!equipoPresentado && (
                                  <div className="col-md-2">
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        let newValues = { ...getValues() };
                                        newValues[`adicional_${c.id}`] = "";
                                        // newValues[`controller_${c.id}`] = ''
                                        archivos[c.id] = null;
                                        reset(newValues);
                                      }}
                                    >
                                      Limpiar
                                    </a>
                                  </div>
                                )}
                              </div>
                            )}
                          </label>
                          <Controller
                            control={control}
                            key={`adicional_${c.id}`}
                            name={`controller_${c.id}`}
                            render={({ onChange, onBlur, value }) => (
                              <input
                                id={`adicional_${c.id}`}
                                name={`adicional_${c.id}`}
                                disabled={
                                  estadoFormulario === "posteando" ||
                                  equipoPresentado
                                }
                                type="file"
                                isCategoria={false}
                                ref={register({
                                  required:
                                    c.requerido &&
                                    (archivos.hasOwnProperty(c.id) &&
                                      archivos[c.id] !== null) ==
                                      archivos[c.id] instanceof File,
                                })}
                                onChange={(e) => {
                                  const maxFileSize = c.peso_max * 1024 * 1024;                                  
                                  
                                  const file = Array.from(e.target.files)[0];
                                  const forbiddenExtensions = ['exe', 'bat', 'com', 'sh']; 
                                  const allowedExtensions = c.tipo.length > 0 ? c.tipo.map((item) => item.extension) : [];
                                  
                                  const fileExtension = file.name
                                    .split(".")
                                    .pop()
                                    .toLowerCase();
                                  if (
                                    (allowedExtensions.length < 1 && !forbiddenExtensions.includes(fileExtension) || (allowedExtensions.length > 0 && allowedExtensions.includes(fileExtension))) &&
                                    file.size <= maxFileSize
                                  ) {
                                    let newArchivos = { ...archivos };
                                    newArchivos[c.id] = file;                                    
                                    setArchivos(newArchivos);
                                    setInputErrorMsg(null);
                                  } else {
                                    if (
                                      ((allowedExtensions.length > 0 && !allowedExtensions.includes(fileExtension)) || (allowedExtensions.length < 1 && forbiddenExtensions.includes(fileExtension)) )
                                    ) {
                                      setFileInputError(true);
                                        if (allowedExtensions.length > 0)
                                     
                                          setInputErrorMsg(
                                            `El archivo debe ser de tipo ${allowedExtensions.join(", ") } y los archivos del tipo  ${forbiddenExtensions.join(", ") } no están permitidos`
                                          )
                                        else 
                                        setInputErrorMsg(
                                          `Los archivos del tipo  ${forbiddenExtensions.join(", ") } no están permitidos`
                                        )
                                      
                                    } else {
                                      setFileInputError(true)
                                      setInputErrorMsg(
                                        `El archivo debe pesar menos de ${c.peso_max} MB`
                                      );
                                    }
                                    e.target.value = null;
                                  }
                                }}
                                style={{ display: "none" }}
                              />
                            )}
                          />
                          {errors[`adicional_${c.id}`] &&
                            errors[`adicional_${c.id}`].type === "required" && (
                              <p className="error text-danger">
                                El campo es obligatorio
                              </p>
                            )}
                             {setFileInputError && 
                              <p className="error text-danger" style={{marginBottom: "0px!important"}}>
                               {inputErrorMsg}
                              </p>
                            }
                            
                        </fieldset>
                      )}
                    </div>
                  ))
                : null
              : // Si hay campos personalizados en la categoria, los prioriza sobre los campos personalizados de la convocatoria
              categoriaData[0].campos_adicionales
              ? categoriaData[0].campos_adicionales.map((c) => (
                  <div
                    className="col-md-6 form-group item-form"
                    key={c.campo_adicional.id}
                  >
                    {c.campo_adicional.tipo === "TXT" ? (
                      <>
                        <label>
                          {c.campo_adicional.titulo}{" "}
                          {c.requerido ? asterisco : ""}
                        </label>
                        <input
                          type="text"
                          key={`adicional_cat_${c.id}`}
                          name={`adicional_${c.id}`}
                          className="form-control"
                          defaultValue={
                            integrante?.campos_adicionales_categoria?.find(
                              (ca) => {
                                return ca.campo_adicional_categoria.id === c.id;
                              }
                            )?.valor || ""
                          }
                          ref={register({
                            required: c.requerido,
                            maxLength: 64,
                          })}
                          onBlur={(e) => {
                            if (e.target.value.length > 128) {                              
                              setInputError(true);
                            } else {
                              setInputError(false);
                            }
                          }}
                          style={inputError ? { borderColor: "red", borderWidth: "3px" } : {}}
                          disabled={equipoPresentado ? "disabled" : ""}
                        />
                          
                        {errors[`adicional_${c.id}`] &&
                          errors[`adicional_${c.id}`].type === "required" && (
                            <p className="error text-danger">
                              El campo es obligatorio
                            </p>
                          )}
                          {inputError && 
                              <p className="error text-danger">
                                El texto no puede superar los 128 caracteres
                              </p>
                            }
                      </>
                    ) : (
                      <fieldset key={`adicional_cat_${c.id}`}>
                        <label style={{ display: "block"}}>
                          {c.campo_adicional.titulo}{" "}
                          {c.requerido ? asterisco : ""}
                        </label>
                        <label
                          key={`adicional_cat_${c.id}`}
                          htmlFor={`adicional_${c.id}`}
                          style={{marginBottom: 0 }}
                        >
                          <div
                            className={`btn btn-primary ${
                              equipoPresentado ? "disabled" : ""
                            }`
                          }
                          style={{marginBottom: 0 }}
                          >
                            <i className="icono-arg-nube-subir-datos" />
                            ELEGIR ARCHIVO 2
                          </div>
                          {archivos[c.id] && (
                            <div className="row">
                              <div className="col-md-10  item-form">
                                {archivos[c.id] instanceof File
                                  ? archivos[c.id].name
                                  : "Ya fue subido un archivo"}
                              </div>
                              {!equipoPresentado && (
                                <div className="col-md-2">
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      let newValues = { ...getValues() };
                                      newValues[`adicional_${c.id}`] = "";
                                      // newValues[`controller_${c.id}`] = ''
                                      archivos[c.id] = null;
                                      reset(newValues);
                                    }}
                                  >
                                    Limpiar
                                  </a>
                                </div>
                              )}
                            </div>
                          )}
                        </label>
                        <Controller
                          control={control}
                          name={`controller_${c.id}`}
                          render={({ onChange, onBlur, value }) => (
                            <input
                              key={`adicional_cat_${c.id}`}
                              id={`adicional_${c.id}`}
                              name={`adicional_${c.id}`}
                              disabled={
                                estadoFormulario === "posteando" ||
                                equipoPresentado
                              }
                              isCategoria={true}
                              type="file"
                              /*ref={register({
                                required:
                                  c.requerido
                              })}*/
                              ref={register({
                                required:
                                  c.requerido &&
                                  (archivos.hasOwnProperty(c.id) &&
                                    archivos[c.id] !== null) ==
                                    archivos[c.id] instanceof File,
                              })}
                              onChange={(e) => {
                                const maxFileSize = c.peso_max * 1024 * 1024;                                  
                                
                                const file = Array.from(e.target.files)[0];
                                const forbiddenExtensions = ['exe', 'bat', 'com', 'sh'];
                                const allowedExtensions = c.tipo.length > 0 ? c.tipo.map((item) => item.extension) : [];
                                const fileExtension = file.name
                                  .split(".")
                                  .pop()
                                  .toLowerCase();
                                if (
                                  (allowedExtensions.length < 1 && !forbiddenExtensions.includes(fileExtension) || (allowedExtensions.length > 0 && allowedExtensions.includes(fileExtension))) &&
                                    file.size <= maxFileSize
                                ) {
                                  let newArchivos = { ...archivos };
                                  newArchivos[c.id] = file;                                    
                                  setArchivos(newArchivos);
                                  setInputErrorMsg(null);
                                } else {
                                  if (
                                    ((allowedExtensions.length > 0 && !allowedExtensions.includes(fileExtension)) || (allowedExtensions.length < 1 && forbiddenExtensions.includes(fileExtension)) )
                                  ) {
                                    setFileInputError(true)                                    
                                    
                                    if (allowedExtensions.length > 0)
                                 
                                      setInputErrorMsg(
                                        `El archivo debe ser de tipo ${allowedExtensions.join(", ") } y los archivos del tipo  ${forbiddenExtensions.join(", ") } no están permitidos`
                                      )
                                    else 
                                    setInputErrorMsg(
                                      `Los archivos del tipo  ${forbiddenExtensions.join(", ") } no están permitidos`
                                    )
                                    
                                  } else {
                                    setFileInputError(true)
                                    setInputErrorMsg(
                                      `El archivo debe pesar menos de ${c.peso_max} MB`
                                    );
                                  }
                                  e.target.value = null;
                                }
                              }}
                              style={{ display: "none" }}                              
                            />
                          )}
                        />
                        {errors[`adicional_${c.id}`] &&
                          errors[`adicional_${c.id}`].type === "required" && (
                            <p className="error text-danger">
                              El campo es obligatorio
                            </p>
                          )}
                            {setFileInputError && 
                              <p className="error text-danger" style={{marginBottom: "0px!important"}}>
                               {inputErrorMsg}
                              </p>
                            }
                      </fieldset>
                    )}
                  </div>
                ))
              : null
          }
        </div>
        {subCategorias.length > 0 &&
          subCategorias.map((subcategoria) => (
            <div key={subcategoria.id} className="row">
              <div className="col-md- form-group item-form">
                <label>{subcategoria.subcategoria}</label>
                <select
                  className="form-control"
                  disabled={equipoPresentado ? "disabled" : ""}
                  name={"aplica_" + subcategoria.id}
                  ref={register({
                    required: {
                      value: true,
                      message: "Seleccione una condición",
                    },
                  })}
                >
                  <option value={""}>Seleccioná una condición</option>
                  {subcategoria.condiciones.length > 0 &&
                    subcategoria.condiciones.map((condicion) => (
                      <option
                        key={condicion.id}
                        value={condicion.id}
                        title={condicion.condicion}
                        selected={
                          condiciones &&
                          condiciones
                            .map(function (c) {
                              return c.id;
                            })
                            .includes(condicion.id)
                        }
                      >
                        {condicion.condicion}
                      </option>
                    ))}
                </select>
                <ErrorMessage
                  errors={errors}
                  name={"aplica_" + subcategoria.id}
                  render={({ message }) => (
                    <p className="error text-danger">{message}</p>
                  )}
                />
              </div>
            </div>
          ))}
        {errores.length > 0 && (
          <div className="alert alert-danger">
            {errores.map((error) => (
              <p>{error}</p>
            ))}
          </div>
        )}

        {acciones === "registro" && (
          <div className="row m-t-3">
            <div className="col-sm-12">
              <button
                type="button"
                className="btn btn-link"
                onClick={setState}
                disabled={estadoFormulario === "posteando" ? "disabled" : ""}
              >
                CANCELAR
              </button>
              <button
                className={
                  estadoFormulario === "posteando"
                    ? "btn btn-primary m-l-1 state-loading"
                    : "btn btn-primary m-l-1"
                }
                type="submit"
                disabled={inputError}
              >
                REGISTRAR EQUIPO
              </button>
            </div>
          </div>
        )}
        {acciones === "integrante" && (
          <div className="row m-t-3">
            <div className="col-sm-12">
              <button
                type="button"
                className="btn btn-link"
                onClick={setState}
                disabled={estadoFormulario === "posteando" ? "disabled" : ""}
              >
                CANCELAR
              </button>
              <button
                type="submit"
                className={
                  estadoFormulario === "posteando"
                    ? "btn btn-primary m-l-1 state-loading"
                    : "btn btn-primary m-l-1"
                }
                disabled={equipoPresentado ? "disabled" : ""}
              >
                {integrante ? "GUARDAR" : "REGISTRAR INTEGRANTE"}
              </button>
            </div>
          </div>
        )}
      </form>
    </Fragment>
  );
};
export default FormularioIntegrante;
