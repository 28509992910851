import React from 'react';
import { Link } from 'react-router-dom';

const ConvocatoriaCard = (props) => {

    const { convocatoria } = props;

    return (
        <div className="col-xs-12 col-sm-6 col-md-6 mb-3">
            <Link to={convocatoria.metadata.slug} className="panel panel-default panel-icon panel-primary">
                <div className="panel-heading">
                    <i className={convocatoria.metadata.iconClass}></i>
                </div>
                <div className="panel-body">
                    <h3 style={{ textTransform: 'uppercase'}}>{convocatoria.nombre}</h3>
                    <div className="text-muted">
                        <p>{convocatoria.descripcionCorta}</p>
                    </div>
                </div>
            </Link>
        </div>
    )
}
export default ConvocatoriaCard;