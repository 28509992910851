import React, {useEffect, useState} from 'react';
import ConvocatoriaCard from './ConvocatoriaCard';

const Convocatorias = (props) => {
    const { convocatorias, fetching } = props;
    const [convocatoriasPor, setConvocatoriasPor] = useState(null)

    useEffect(() =>{
        if (convocatorias){
            const sortedConvocatorias = convocatorias.filter(convocatoria => convocatoria.vigente || convocatoria.anterior)
            .sort((a, b) => new Date(b.etapas['LA'].fechaInicio) - new Date(a.etapas['LA'].fechaInicio))
            const [vigentes, noVigentes] = sortedConvocatorias.reduce((result, element) => {
                if (element.vigente){
                    result[0].push(element)
                } else if (element.anterior) {
                    result[1].push(element)
                }
                return result;
            }, [[], []]);
            setConvocatoriasPor({vigentes, noVigentes})
        }
    }, [convocatorias])


    return (
        <section>
            <article className="container content_format">
                <div className="col-md-8 col-md-offset-2">
                    <div className="panel-pane pane-texto">
                        <div className="pane-content">
                            <div className="">
                                <p>La característica principal de esta plataforma de concursos de ideas o propuestas es que –según lo que establezcan las respectivas Bases y Condiciones- puede configurarse de manera tal que los participantes presenten sus trabajos en forma completamente anónima, por lo que los jurados especialmente designados para evaluar cada convocatoria pueden juzgar la pertinencia y calidad de los trabajos sin conocer la identidad, la pertenencia institucional o la trayectoria científico-tecnológica de los equipos que se presenten.</p>
                                <div className="d-flex justify-content-between">
                                    <h4>Convocatorias:</h4>
                                    <a href={'assets/files/InstructivoGeneral.pdf'}
                                       style={{marginTop: 'auto', marginBottom: '0.7em'}}>
                                            ¿Necesitás ayuda?
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel-separator"/>
                    {
                        fetching ?
                            <p>Cargando...</p>
                            :
                            <div className="panel-pane pane-atajos">
                                <div className="pane-content">
                                    <div className="row panels-row">
                                        {/* Accordion Vigentes */}
                                        <div className="panel-group w-100" id="accordion">
                                            <div className="panel">
                                                <div className="panel-heading" data-toggle="collapse"
                                                     data-target="#vigentes" aria-expanded="true"
                                                     aria-controls="vigentes" role='button'>
                                                    <h4 className="panel-title">
                                                        <p style={{
                                                            margin: 'auto',
                                                            color:'#0272bb',
                                                            fontWeight: 'bold'
                                                        }}> Convocatorias Vigentes</p>
                                                    </h4>
                                                </div>
                                                <div id="vigentes" className="panel-collapse collapse show">
                                                    <div className="panel-body">
                                                        <div className="row">
                                                            {
                                                                convocatoriasPor?.vigentes ?
                                                                  convocatoriasPor?.vigentes.map(convocatoria => (
                                                                      <ConvocatoriaCard
                                                                        key={convocatoria.id}
                                                                        convocatoria={convocatoria}
                                                                      />
                                                                    )
                                                                  )
                                                                  :
                                                                  <p>No hay convocatorias vigentes actualmente.</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Accordion No Vigentes */}
                                        <div className="panel-group w-100" id="accordion">
                                            <div className="panel">
                                                <div className="panel-heading" data-toggle="collapse"
                                                     data-target="#no-vigentes"
                                                     aria-controls="no-vigentes" role='button'>
                                                    <h4 className="panel-title">
                                                        <p style={{
                                                            margin: 'auto',
                                                            color:'#0272bb',
                                                            fontWeight: 'bold'
                                                        }}> Convocatorias Anteriores (ya finalizadas)</p>
                                                    </h4>
                                                </div>
                                                <div id="no-vigentes" className="panel-collapse collapse">
                                                    <div className="panel-body">
                                                        {
                                                            convocatoriasPor?.noVigentes.length ?
                                                                convocatoriasPor?.noVigentes.map(convocatoria => (
                                                                        <ConvocatoriaCard
                                                                            key={convocatoria.id}
                                                                            convocatoria={convocatoria}
                                                                        />
                                                                    )
                                                                )
                                                                :
                                                                <p>No existen convocatorias anteriores.</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                    }

                </div>
            </article>
        </section>
    )
}
export default Convocatorias;
