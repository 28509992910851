import React, { Fragment, useState } from 'react';
import { useForm } from "react-hook-form";
import { resetPassword } from '../../api/user';
import {buildUrl} from "../../api/utils";

const RecuperarLlave = (props) => {

    const { setAccountState, convocatoria } = props;

    const btnLikeA = {
        background: 'transparent',
        border: 'none',
        padding: '0!important',
        color: '#069',
        textDecoration: 'underline',
        cursor: 'pointer',
    }

    const { register, errors, handleSubmit } = useForm();
    const [errores, setErrores] = useState([]);
    const [reseteandoLlave, setReseteandoLlave] = useState(false);
    const [dni, setDni] = useState('')

    // const onSubmit = async (data) => {
    //     setReseteandoLlave(true)
        // const response = await resetPassword({ ...data, 'convocatoria': convocatoria.id });
        // switch (response) {
        //     case 200:
        //         setAccountState('llave-reseteada')
        //         break;
        //     case 404:
        //         setErrores(["El email y/o número de documento ingresado no está registrado en la convocatoria."])
        //         break;
        //     default:
        //         console.log(`Algo salio mal recuperando la clave. Error: ${response}`);
        // }
        // setReseteandoLlave(false)
    // }
    const onSubmit = () => {
        const endpoint = buildUrl('/llave/recuperar')
        setErrores([])
        setReseteandoLlave(true)
        fetch(endpoint, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({ dni: dni, id_convocatoria: convocatoria.id})
        }).then(response => {
            setReseteandoLlave(false)
            switch (response.status) {
                case 200:
                    setAccountState('llave-reseteada')
                    break;
                case 400:
                    setErrores(['El equipo no fue presentado, no hay llave para recuperar.'])
                    break;
                case 401:
                    setErrores(["El DNI ingresado no está registrado como Contacto Principal en la convocatoria."])
                    break;
                case 409:
                    setErrores(["El equipo ya presentó propuesta. No podrá recuperar Llave."])
                    break;
                default:
                    setErrores([`Algo salio mal recuperando la llave. Intente nuevamente.`]);
            }
        })
    }


    return (
        <Fragment>
            <div className="panel-pane pane-texto">
                <div className="pane-content">
                    <div className="row m-b-2">
                        <div className="col-md-6">
                            <h4 className="activities-sidbar">Recuperar Llave</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div id="errores" aria-live="polite" className="alert alert-danger hidden">
                                <h5>Se han producido los siguientes errores:</h5>
                                <ol>
                                    <li>Ingresá tu usuario</li>
                                    <li>Ingresá tu clave</li>
                                </ol>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-md-10 form-group item-form">
                                        <label>Ingresa el DNI del Contacto Principal</label>
                                        <input
                                            type="text"
                                            name="numDoc"
                                            className="form-control"
                                            ref={register({ required: true })}
                                            onChange={(e) => setDni(e.target.value)}
                                            disabled={reseteandoLlave}
                                        />
                                        {errors.numDoc && errors.numDoc.type === "required" &&
                                            <p className="error text-danger">Ingresá el DNI</p>}
                                    </div>
                                </div>
                                {errores.length > 0 &&
                                    errores.map(error => (
                                        <p key={error} className="error text-danger">{error}</p>
                                    ))
                                }
                                <div className="row">
                                    <div className="col-sm-3">
                                        <button
                                            type="submit"
                                            className={
                                                reseteandoLlave ?
                                                    "btn btn-primary state-loading" :
                                                    "btn btn-primary"}
                                        >RECUPERAR</button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-10">
                                        <hr />
                                    </div>
                                </div>
                            </form>

                            <div className="row">
                                <div className="col-xs-12">

                                    <p><button onClick={() => setAccountState('llave')} style={btnLikeA}>Ingresar con mi llave</button></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="panel-separator"></div>
        </Fragment>
    )
}
export default RecuperarLlave;